.footer {
  padding: 60px 0;
  font-size: 11px;
}
.footer__links {
  margin: 0 auto;
  display: block;
  text-align: center;
}
.footer__links a {
  text-decoration: none;
  color: #000;
}
.footer__links a:before {
  content: '|';
  padding-left: 15px;
  margin-right: 15px;
}
.footer__links a:first-child:before {
  content: '';
  padding: 0;
  margin: 0;
}
.footer__social {
  text-align: center;
  margin-top: 15px;
  margin: 30px auto 0;
  width: 95px;
}
.footer__social:after {
  content: '';
  display: block;
  clear: both;
}
.footer__social a {
  margin-left: 15px;
  float: left;
}
.footer__social a i {
  border: 1px solid #000;
  padding: 14px 9px 10px 10px;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  color: #000;
}
.footer__social a i:hover {
  background: #000;
  color: #fff;
}
.footer__social a:first-child {
  margin-left: 0;
}
