.coming-soon {
  background: #fafafa;
}
.coming-soon .coming-soon__container {
  width: 425px;
  max-width: 100%;
  margin: 0 auto;
  color: #fff;
  margin-top: 30px;
  position: relative;
}
.coming-soon .coming-soon__header {
  padding: 30px 30px 15px;
  margin-bottom: 0;
  text-align: center;
  font-weight: 300;
  font-size: 20px;
}
@media screen and (max-width: 450px) {
  .coming-soon .coming-soon__header {
    padding: 15px 15px 0;
  }
}
.coming-soon .coming-soon__content {
  padding: 15px;
}
.coming-soon .coming-soon__icon {
  font-size: 90px;
  text-align: center;
  margin-bottom: 15px;
  color: #000;
}
.coming-soon body {
  background: inherit;
}
.coming-soon .wrap__header {
  width: 700px;
  max-width: 90%;
  text-transform: uppercase;
}
.coming-soon .form {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.coming-soon .form b {
  margin-bottom: 0px;
  background: #fff;
  color: #444;
  padding: 5px 15px;
}
.coming-soon .form b:after {
  font-weight: 500;
  font-size: 20px;
}
.coming-soon .form input {
  padding: 15px;
  border: 0;
  outline: none;
}
