.terms h4 {
  margin-bottom: 5px;
}
.terms p {
  margin-top: 0;
  margin-bottom: 30px;
}
.terms .wrap__header.terms {
  min-width: 320px;
}
.terms .wrap__header.business {
  min-width: 380px;
}
