/** styles/components/_blog.less **/
.blog:after {
  content: '';
  display: block;
  clear: both;
}
.blog__header {
  background: #000;
  background-size: 80px;
  height: 250px;
  color: #fff;
  position: relative;
}
.blog__header h1 {
  font-size: 25px;
  position: absolute;
  transform: translate3d(-50%, 0%, 0);
  left: 50%;
  letter-spacing: 5px;
  font-weight: 500;
  margin-top: 75px;
}
.blog__container {
  max-width: 800px;
  margin: -80px auto 0;
  position: relative;
}
.blog__recent-post {
  position: relative;
  background: #fff;
  box-shadow: 2px 2px 10px -4px #c7c7c7;
  overflow: hidden;
  display: block;
  text-decoration: none;
  color: inherit;
  min-height: 300px !important;
}
.blog__recent-post:hover .blog__recent-post__content {
  margin-top: -160px;
}
.blog__recent-post h3 {
  margin-bottom: 10px;
}
.blog__recent-post__content {
  padding: 15px;
  margin-top: -76px;
  background: #fff;
  position: relative;
  transition: all .25s ease;
}
.blog__recent-post__content i {
  margin-right: 5px;
}
.blog__recent-post__content p {
  margin-bottom: 0;
  margin-top: 10px;
  height: 72px;
}
.blog__recent-post__sub-header {
  color: #999;
}
.blog__recent-post__tag {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
  padding: 5px 7px 5px 10px;
  z-index: 9;
  border-radius: 3px;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.blog__recent-post--no-slide:hover .blog__recent-post__content {
  margin-top: -76px;
}
.blog__recent-post--no-slide .blog__recent-post__content {
  position: absolute;
  bottom: 0;
  margin-top: 0;
  width: 100%;
}
.blog__section {
  display: block;
  width: 100%;
  padding: 30px;
  background: #fff;
  margin: 30px 0;
}
.blog__section:after {
  content: '';
  display: block;
  clear: both;
}
.blog__products {
  background: #fff;
  margin: 60px 0;
  padding: 30px 60px;
}
.blog__products .wrap__row {
  margin: 0;
}
.blog__products .wrap__header {
  max-width: 300px;
}
.blog__products .grid {
  margin-bottom: 0;
}
.blog__main-upload {
  border: 4px dashed #2980b9;
  min-height: 450px;
  margin: 15px;
  position: relative;
  background: #fff;
  display: block;
  max-height: 450px;
  overflow: hidden;
  margin-bottom: 30px;
}
.blog__main-upload i {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  font-size: 60px;
  color: #2980b9;
}
.blog__main-upload input {
  display: none;
}
.blog__main-image {
  max-height: 450px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
}
.blog__main-image__content {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  padding: 30px;
  background: rgba(0, 0, 0, 0.5);
}
.blog__main-image__content h3 {
  margin-bottom: 0;
}
.blog__main-image__content i {
  margin-right: 10px;
}
.blog__share-buttons {
  position: absolute;
  bottom: 0;
}
.blog__share-buttons a {
  padding: 30px;
  cursor: pointer;
  display: block;
  color: #fff;
  float: left;
}
.blog__share-buttons__button--facebook {
  background: #3b5999;
}
.blog__share-buttons__button--twitter {
  background: #55acee;
}
.blog__share-buttons__button--is-live {
  background: #27ae60;
}
.blog__share-buttons__button--is-not-live {
  background: #e74c3c;
}
.blog__post {
  position: relative;
}
.blog__post label + h3 {
  margin-top: 30px;
}
.blog__post h3 {
  font-size: 22px;
}
.blog__post p {
  font-size: 18px;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}
.blog__post img {
  min-height: 150px;
  max-width: 450px;
  display: block;
  margin: 10px auto;
}
.blog__post__title {
  text-align: center;
  outline: none;
  background: #000;
  padding: 15px 10px;
  color: #fff;
  margin: 0;
  position: relative;
  left: 50%;
  max-width: 580px;
  margin-left: -290px;
}
.blog__post__content {
  background: #fff;
  padding: 30px;
  min-height: 400px;
  text-align: center;
}
.blog__post__content iframe {
  margin: 0 auto;
  max-width: initial;
}
.blog__post__user {
  padding-top: 15px;
  margin-top: 30px;
  border-top: 1px solid #eee;
}
.blog__post__user img {
  height: 150px;
  width: 150px;
  border-radius: 90px;
  border: 1px solid #eee;
  box-shadow: 1px 3px 10px 0px #d9d9d9;
}
.blog__post__image-upload,
.blog__post__embed {
  min-height: 150px;
  max-width: 450px;
  border: 3px dashed #2980b9;
  display: block;
  margin: 10px auto;
  position: relative;
  cursor: pointer;
}
.blog__post__image-upload i,
.blog__post__embed i {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  font-size: 30px;
  color: #2980b9;
}
.blog__post__image-upload input,
.blog__post__embed input {
  display: none;
}
.blog__post__image-upload--square,
.blog__post__embed--square {
  margin: 15px auto 0;
  height: 350px;
  width: 350px;
}
.blog__post__image-upload--square img,
.blog__post__embed--square img {
  width: 100%;
  height: 100%;
  max-width: initial;
  margin: 0;
}
.blog__post__embed {
  min-height: 230px;
}
.blog__post__embed i {
  transform: translate3d(-50%, -130%, 0);
}
.blog__post__embed input {
  display: block;
  border: 0;
  border-top: 3px dashed #2980b9;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #e0e0e0;
}
.blog__post__embed--has-content {
  max-width: initial;
}
.blog__post__buttons {
  position: fixed;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  bottom: 0;
  z-index: 1;
}
.blog__post .blog__post__button {
  padding: 15px;
  float: left;
  background: #000;
  color: #fff;
  cursor: pointer;
}
.blog__post .blog__post__button:hover {
  background: #222;
}
.blog__post .blog__post__button--input {
  background: #fff;
  border: 1px solid #000;
  font-weight: 300;
  color: #444;
  width: 230px;
  float: right;
}
.blog__post .blog__post__button--input:hover {
  background: #fff;
}
.blog__row {
  position: relative;
}
.blog__row:hover {
  background: #FFFFE0;
}
.blog__row:hover .blog__row__options {
  display: block;
  height: 40px;
}
.blog__row__options {
  position: absolute;
  transform: translate3d(-50%, 0, 0);
  left: 50%;
  top: -36px;
  display: none;
}
.blog__row__options a {
  padding: 5px 10px;
  margin-left: 5px;
  float: left;
  background: #2980b9;
  border: 1px solid #20638f;
  line-height: 0;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0px 5px 15px -2px #999;
}
.blog__row__options a:first-child {
  margin-left: 0;
}
.blog__row__options a[data-type="delete"] {
  background: #e74c3c;
  border-color: #c0392b;
}
.blog__tooltip {
  float: left;
  position: relative;
}
.blog__tooltip__content {
  position: absolute;
  bottom: 22px;
  background: #000;
  color: #fff;
  border-radius: 2px;
  width: 116px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 11px;
  text-align: left;
  z-index: 1;
}
.blog__tooltip__content:after,
.blog__tooltip__content:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.blog__tooltip__content:after {
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #000;
}
.blog__tooltip__content:before {
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid;
  border-bottom-color: #000;
}
.blog__tooltip__link {
  float: left;
  padding: 15px;
  cursor: pointer;
  -webkit-appearance: none;
  background: transparent;
  color: #fff;
  border: 0;
  outline: none;
}
.blog__tooltip__link:hover {
  background: #222;
}
.blog__tooltip__link i {
  pointer-events: none;
}
.blog__comments {
  background: #fff;
  padding: 15px 30px;
}
@media screen and (max-width: 720px) {
  .blog__post__title {
    width: 95%;
    margin: 0 auto;
    left: 0;
  }
  .blog__post__content__embed {
    width: 100% !important;
  }
}
