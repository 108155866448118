@import '../_functions.less';
@import '../_variables.less';
.show-case{
	&__header{
		width: 350px;
		margin: @spacing-xl*2 auto 0;
	}
	&__section{
		width: 100%;
		margin: @spacing-xl 0 0;
		h3{
			max-width: 340px;
			margin-bottom: @spacing-large;
		}
		p{
			margin-bottom: @spacing-large;
			line-height: 2;
			margin-top: 0;
		}
	}
	&__buttons{
		width: 100%;
		margin: 0 auto;
		.clearfix();
		a{
			margin-left: 3%;
			&:first-child{
				margin-left: 0;
			}
		}
		.button--outline{
			float: left;
			min-width: 55%;
		}
		.button--social-facebook,
		.button--social-twitter{
			width: 18%;
			padding: @spacing-base 0;
		}
	}
}