header {
  background: #fff;
  border-bottom: 1px solid #eee;
  z-index: 100;
  position: relative;
  position: fixed;
  top: 0;
  width: 100%;
}
header:after {
  content: '';
  display: block;
  clear: both;
}
.header-fix {
  height: 45px;
}
/* block */
.nav {
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
  /* elements */
  /* modifiers */
}
.nav:after {
  content: '';
  display: block;
  clear: both;
}
.nav li {
  float: left;
  text-transform: uppercase;
  font-size: 12px;
}
.nav li a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: inherit;
}
.nav li a:hover,
.nav li a:focus {
  background: #f9f9f9;
}
.nav li i {
  margin-right: 10px;
}
.nav__logo {
  width: 38px;
  position: absolute;
  left: 50%;
  margin-left: -19px;
  margin-top: -6px;
  top: 50%;
}
.nav__logo div {
  background: #000;
  height: 10px;
  width: 10px;
  margin-left: 4px;
  float: left;
  transform: rotate(45deg);
}
.nav__logo div:first-child {
  margin-left: 0;
}
.nav .nav__sub-li {
  display: block;
  padding: 15px;
  min-width: 130px;
}
.nav .nav__sub-li:hover + .nav__overlay,
.nav .nav__sub-li:focus + .nav__overlay,
.nav .nav__sub-li:active + .nav__overlay {
  display: block;
}
.nav .nav__sub-li:hover .nav__sub-nav,
.nav .nav__sub-li:focus .nav__sub-nav,
.nav .nav__sub-li:active .nav__sub-nav {
  display: block;
}
.nav .nav__sub-li span > a {
  margin: -15px;
  text-align: center;
}
.nav .nav__sub-li--list {
  position: relative;
}
.nav .nav__sub-li--list:hover,
.nav .nav__sub-li--list:focus {
  background: #000;
  color: #fff;
}
.nav .nav__sub-li--list .nav__sub-nav {
  background: #000;
  width: 100%;
  left: 0;
}
.nav .nav__sub-li--list .nav__sub-nav__item {
  border-top: 1px solid #222;
  text-transform: capitalize;
  padding: 0;
}
.nav .nav__sub-li--list .nav__sub-nav__item a {
  padding: 15px;
  display: block;
}
.nav .nav__sub-li--list .nav__sub-nav__item a:hover {
  background: #222;
}
.nav .nav__sub-nav {
  position: absolute;
  top: 45px;
  background: #fff;
  z-index: 100;
  display: none;
}
.nav .nav__sub-nav--collections {
  width: 100%;
  left: 0;
  border-top: 1px solid #eee;
}
.nav .nav__sub-nav__item {
  padding: 15px;
}
.nav .nav__sub-nav__item img {
  width: 250px;
  margin: 0 auto;
}
.nav .nav__sub-nav__item a {
  padding: 0;
  display: initial;
}
.nav .nav__sub-nav__item a:after {
  content: '';
  display: block;
  clear: both;
}
.nav .nav__sub-nav__item a:hover {
  background: #fff;
}
.nav__overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  height: 100%;
  width: 100%;
  left: 0;
  top: 45px;
  display: none;
}
.nav--left {
  float: left;
}
.nav--right {
  float: right;
}
.nav--mobile {
  display: none;
}
@media screen and (max-width: 720px) {
  header {
    display: none;
  }
  header.nav--mobile {
    display: block;
  }
  .nav li i {
    margin-right: 0;
    font-size: 14px;
  }
  .nav li a:hover {
    color: #000;
    background: #fff;
  }
  .nav__logo div {
    background: #fff;
  }
  .nav--mobile {
    color: #fff;
    border-bottom: 0;
    background: #000;
  }
}
