@import '../_functions.less';
@import '../_variables.less';
.autocomplete{
	position: relative;
	input{
		outline: none;
	}
	& &__list{
		position: absolute;
		border: 1px solid @color-info;
		background: @color-grey-light;
		width: 100%;
		z-index: 1;
		&:empty{
			display: none;
		}
	}
	& &__item{
		padding: @spacing-small;
		display: block;
		cursor: pointer;
		&:hover{
			font-weight: 400;
			background: @color-info-light;
		}
		// modifiers
		&--no-result{
			pointer-events: none;
			&:hover{
				background: @color-grey-light;
				font-weight: 300;
			}
		}
	}
}

// modifiers
.autocomplete__list.autocomplete__list--bottom{
	.arrow(@spacing-xs, @color-grey-light, bottom, 20px, 0.5px, @color-info);
}
.autocomplete__list.autocomplete__list--top{
	.arrow(@spacing-xs, @color-grey-light, top, 20px, 0.5px, @color-info);
}