.toggle {
  width: 60px;
  margin: 10px 0;
}
.toggle input {
  display: none;
}
.toggle label {
  cursor: pointer;
}
.toggle__content {
  background: #e74c3c;
  border: 1px solid #c0392b;
  border-radius: 30px;
  transition: all .25s ease;
}
.toggle__button {
  height: 22px;
  width: 22px;
  transition: all .25s ease;
  border-radius: 30px;
  background: #f9f9f9;
  border: 0.5px solid #eee;
  position: relative;
  left: 0;
}
.toggle input:checked + .toggle__content {
  background: #27ae60;
  border-color: #1e8449;
}
.toggle input:checked + .toggle__content .toggle__button {
  margin-left: 100%;
  left: -22px;
}
.toggle--mini {
  margin: 0;
  width: 45px;
}
.toggle--mini .toggle__button {
  height: 16px;
  width: 16px;
}
.toggle--mini input:checked + .toggle__content {
  background: #27ae60;
  border-color: #1e8449;
}
.toggle--mini input:checked + .toggle__content .toggle__button {
  margin-left: 100%;
  left: -16px;
}
