@import '../_functions.less';
@import '../_variables.less';

@slider-height: 20px;
@handle-height: @spacing-large;

.slider{
	background: @color-grey;
	width: 100%;
	height: @slider-height;
	border-radius: 30px;
	border: 1px solid @color-grey-dark;
	position: relative;
	&__handle{
		border: @spacing-small solid @color-blue-dark;
		background: #fff;
		width: @handle-height;
		height: @handle-height;
		position: absolute;
		top: -6px;
		left: -1px;
		border-radius: 30px;
		z-index: 10;
		cursor: -webkit-grab;
	}
	&__progress{
		background: @color-blue-light;
		position: absolute;
		left: -1px;
		top: -1px;
		height: @slider-height;
		width: @handle-height;
		pointer-events: none;
		z-index: 0;
		border-radius: 30px;
		border: 1px solid @color-blue;
	}
	&__output{
		text-align: center;
		font-weight: 500;
		font-size: 18px;
	}
	//modifiers
	&--space{
		margin: @spacing-base + @spacing-small  0 @spacing-base;
	}
}