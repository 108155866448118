@import '../_functions.less';
@import '../_variables.less';
/** pages/_faqs.less **/
.faqs{
	.wrap__header{
		max-width: 100%;
		width: 430px;
	}
	h3{
		margin-bottom: @spacing-small;
		margin-top: 0;
	}
	p{
		margin-top: 0;
		margin-bottom: @spacing-large;
	}
	&__payments{
		padding: 0;
		margin: 0;
		list-style: none;
		li{
			margin-bottom: @spacing-xs;
			.clearfix();
		}
		img{
			float: left;
			width: 35px;
			margin-right: @spacing-small;
		}
		span{
			float: left;
			margin-top: 3px;
		}
	}
}