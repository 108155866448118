@import '../_functions.less';
@import '../_variables.less';
.home-image{
	position: relative;
	img{
		display: block;
		width: 100%;
	}
	&__header{
		position: absolute;
		bottom: -24px;
		padding: 15px 10px 15px 15px;
		font-size: 15px;
		background: #000;
		margin: 0;
		color: #fff;
		text-transform: uppercase;
		font-size: 18px;
		letter-spacing: 5px;
		font-weight: 300;
		transform: translateX(-50%);
		left: 50%;
		text-align: center;
	}
	//responsive
	@media screen and (max-width: @screen-size-small){
		//blockifying
		.home-image{
			&__header{
				width: 80%;
			}
		}
	}
}

.wrap{
	&--home{
		.video{
			width: 800px;
			height: 450px;
			@media screen and (max-width: @screen-size-small){
				width: 380px;
				height: 220px;
			}
		}
	}
}

.reveal-item{
	a{
		margin-left: @spacing-xs;
		color: blue;
		cursor: pointer;
		&:hover{
			text-decoration: underline;
		}
	}
}
