.autocomplete {
  position: relative;
}
.autocomplete input {
  outline: none;
}
.autocomplete .autocomplete__list {
  position: absolute;
  border: 1px solid #3498db;
  background: #fafafa;
  width: 100%;
  z-index: 1;
}
.autocomplete .autocomplete__list:empty {
  display: none;
}
.autocomplete .autocomplete__item {
  padding: 10px;
  display: block;
  cursor: pointer;
}
.autocomplete .autocomplete__item:hover {
  font-weight: 400;
  background: #e1f0fa;
}
.autocomplete .autocomplete__item--no-result {
  pointer-events: none;
}
.autocomplete .autocomplete__item--no-result:hover {
  background: #fafafa;
  font-weight: 300;
}
.autocomplete__list.autocomplete__list--bottom:after,
.autocomplete__list.autocomplete__list--bottom:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.autocomplete__list.autocomplete__list--bottom:after {
  top: 100%;
  left: 20px;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fafafa;
}
.autocomplete__list.autocomplete__list--bottom:before {
  top: 100%;
  left: 20px;
  margin-left: -6px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid;
  border-top-color: #3498db;
}
.autocomplete__list.autocomplete__list--top:after,
.autocomplete__list.autocomplete__list--top:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.autocomplete__list.autocomplete__list--top:after {
  bottom: 100%;
  left: 20px;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fafafa;
}
.autocomplete__list.autocomplete__list--top:before {
  bottom: 100%;
  left: 20px;
  margin-left: -6px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid;
  border-bottom-color: #3498db;
}
