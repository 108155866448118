.off-screen {
  position: fixed;
  top: 0;
  width: 85%;
  background: #fff;
  z-index: 300;
  height: 100%;
  transform: translate3d(-100%, 0, 0);
  transition: all .25s ease;
}
.off-screen ul {
  margin: 0;
  padding: 0;
}
.off-screen ul li {
  list-style: none;
  border-bottom: 1px solid #eee;
}
.off-screen ul li a {
  padding: 15px;
  display: block;
  color: inherit;
  text-decoration: none;
}
.off-screen__header {
  margin: 0;
  padding: 15px;
  background: #000;
  color: #fff;
  font-weight: 300;
}
.off-screen__sub-nav-li:hover .off-screen__sub-nav {
  display: block;
}
.off-screen__sub-nav-li span {
  display: block;
  padding: 15px;
}
.off-screen__sub-nav {
  display: none;
  background: #fafafa;
  border-top: 1px solid #eee;
}
.off-screen--active {
  transform: translate3d(0%, 0, 0);
}
