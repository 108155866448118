@import '../_functions.less';
@import '../_variables.less';
/** styles/components/_user-block.less **/
.user-block{
	margin-bottom: @spacing-base;
	.clearfix();
	//elements
	&__image{
		width: 65px;
		float: left;
		margin-right: @spacing-base;
	}
	&__content{
		&__name{
			font-weight: 500;
		}
		&__email{
			font-style: italic;
			color: @color-grey-darker;
		}
		&__sign-out{
			margin-top: @spacing-xs;
			a{
				color: @color-grey-darker;
				font-style: italic;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}