.landing-page {
  position: relative;
}
.landing-page__bg {
  height: 100vh;
  width: auto;
  margin: 0 auto;
}
.landing-page__logo {
  width: 135px;
  margin: 0 auto 60px;
}
.landing-page__signup {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  color: #fff;
  width: 90%;
  max-width: 520px;
  text-shadow: 0px 0px 10px #000;
}
.landing-page__signup__header {
  letter-spacing: 5px;
  text-align: center;
}
.landing-page__signup__subheader {
  text-align: center;
  margin-bottom: 15px;
  display: block;
}
