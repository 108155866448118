@import '../_functions.less';
@import '../_variables.less';
.list{
	background: @color-grey;
	&__item{

	}
}

.list--large{
	border: 1px solid @color-border;
	.list{
		&__item{
			padding: @spacing-large;
			display: block;
			text-align: center;
			font-size: 30px;
			text-decoration: none;
			color: inherit;
			border-top: 1px solid @color-border;
			&:hover{
				background: @color-blue;
				color: #fff;
				border-color: @color-blue-dark;
			}
			&:first-child{
				border-top: 0;
			}
		}
	}
}