@import '../_functions.less';
@import '../_variables.less';
.colour-selector{
	.clearfix();
	input[type="color"]{
		visibility: hidden;
		opacity: 0;
		width: 0;
		height: 0;
		padding: 0;
		margin: 0;
		line-height: 0;
		float: left;
		border: 0;
		font-size: 0;
	}
	&__space{
		margin-left: @spacing-base;
		padding-left: @spacing-base;
		border-left: 1px solid @color-border;
		float: left;
	}
	&__colours{
		margin-bottom: @spacing-base;
		.clearfix();
		&__header{
			font-weight: 500;
			margin-bottom: @spacing-xs;
		}
	}
	&__new-colours,
	&__current-colours{
		float: left;
		margin-right: @spacing-xs;
	}
	&__saved-colours{
		margin-right: @spacing-xs;
		float: left;
	}
	&__colour{
		padding: 20px;
		float: left;
		border-radius: 30px;
		cursor: pointer;
		border: 3px solid @color-border;
		margin-right: @spacing-xs;
		transform: scale(0.9);
		transition: all .1s ease;
		&:hover{
			transform: scale(1);
		}
		//modifiers
		&--black{
			background: #000;
			&:hover{
				background: lighten(#000, 20%)
			}
		}
		&--white{
			background: #fff;
			&:hover{
				background: darken(#fff, 4%)
			}
		}
		&--checked{
			position: relative;
			color: @color-border;
			border-color: @color-border;
			transform: scale(1);
			&:after{
				content: '\f00c';
				font-family: 'FontAwesome';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);
				color: @color-grey-darker;
				border-radius: 30px;
				padding: 4px;
				font-size: 8px;
				background: @color-border;
			}
		}
		&--selected{
			color: @color-blue;
			border-color: @color-blue;
			transform: scale(1);
			&:after{
				background-color: @color-blue;
				color: #fff;
			}
		}
		&--add{
			border: 2px dashed;
			color: @color-blue;
			position: relative;
			&:after{
				content: '\f067';
				font-family: 'FontAwesome';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);
				padding: 0;
				color: @color-blue;
				background: transparent;
				font-size: 14px;
			}
		}
	}
	//child blocks
	.tooltip{
		margin-left: @spacing-xs;
		&:first-child{
			margin-left: 0;
		}
		.colour-selector__colour{
			margin: 0;
		}
		+ .colour-selector__new-colours{
			padding-left: @spacing-xs;
		}
		&__content{
			bottom: 55px;
			text-align: center;
			input[type="text"]{
				background: transparent;
				color: #fff;
				padding: 0;
				font-weight: 300;
				font-size: 11px;
				border: 0;
				outline: none;
				text-align: center;
			}
		}
	}
}