@import '../_functions.less';
@import '../_variables.less';

.newsletter-signup{
	&__form{
		&__tagline{
			color: #fff;
			text-align: center;
			position: absolute;
			width: 100%;
			left: 0;
			top: 50%;
			transform: translate3d(0, -50%, 0);
			letter-spacing: 3px;
		}
		.grid{
			margin: 0;
		}
		.grid__item{
			position: relative;
			padding: 0;
		}
		.form{
			padding: @spacing-large;
		}
	}
}