.order {
  margin-bottom: 15px;
}
.order:after {
  content: '';
  display: block;
  clear: both;
}
.order:first-of-type {
  padding-top: 0;
}
.order .order__content {
  padding: 15px;
  border: 1px solid #eee;
}
.order .order__row {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.order .order__row:after {
  content: '';
  display: block;
  clear: both;
}
.order .order__row:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}
.order .order__column {
  float: left;
  margin-top: 25px;
  padding: 0 10px;
}
.order .order__column--image {
  margin-top: 0;
  padding: 0;
  width: 70px;
}
.order .order__column--name {
  width: 35%;
}
.order .order__column--size {
  width: 15%;
}
.order .order__column--price {
  float: right;
}
.order .order__top {
  margin-top: 0;
  padding: 15px;
  background: #f9f9f9;
  border: 1px solid #eee;
  border-bottom: 0;
  text-transform: uppercase;
  font-weight: 500;
}
.order .order__top:after {
  content: '';
  display: block;
  clear: both;
}
.order .order__bottom {
  margin-top: 0;
  padding: 15px;
  background: #f9f9f9;
  border: 1px solid #eee;
  border-top: 0;
}
.order .order__bottom:after {
  content: '';
  display: block;
  clear: both;
}
.order .order__created {
  float: left;
}
.order .order__created b {
  margin-right: 10px;
}
.order .order__created i {
  margin: 0 5px 0 0;
}
.order .order__created > span:first-child {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #eee;
}
.order .order__more-info {
  float: right;
  text-decoration: none;
  color: inherit;
}
.order .order__more-info i {
  margin-right: 5px;
}
.recent-order {
  background: #f9f9f9;
}
.recent-order .recent-order__items {
  border: 1px solid #eee;
  padding: 15px;
  background: #fff;
  clear: both;
}
.recent-order .recent-order__row {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}
.recent-order .recent-order__row:after {
  content: '';
  display: block;
  clear: both;
}
.recent-order .recent-order__row:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}
.recent-order .recent-order__image {
  width: 15%;
  padding-right: 15px;
  float: left;
}
.recent-order .recent-order__name {
  width: 35%;
  float: left;
}
.recent-order .recent-order__size {
  width: 15%;
  float: left;
}
.recent-order .recent-order__quantity {
  width: 5%;
  float: left;
}
.recent-order .recent-order__total {
  float: right;
}
.recent-order .recent-order__address,
.recent-order .recent-order__card {
  background: #fff;
  border: 1px solid #eee;
}
.recent-order .recent-order__address__header,
.recent-order .recent-order__card__header {
  padding: 15px;
  margin-bottom: 0;
  border-bottom: 1px solid #eee;
}
.recent-order .recent-order__address__content,
.recent-order .recent-order__card__content {
  padding: 15px;
}
.recent-order .recent-order__address__content:after,
.recent-order .recent-order__card__content:after {
  content: '';
  display: block;
  clear: both;
}
.recent-order .recent-order__address__row,
.recent-order .recent-order__card__row {
  line-height: 1.4;
}
.recent-order .recent-order__address img,
.recent-order .recent-order__card img {
  width: 30px;
  float: right;
}
.recent-order .recent-order__grand-total {
  padding: 15px;
  margin: -15px -15px 15px;
  background: #fff;
  border-bottom: 1px solid #eee;
}
.recent-order .recent-order__grand-total span {
  float: right;
}
.recent-order__totals {
  padding: 15px;
  background: #000;
  color: #fff;
}
.recent-order__totals__row {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #222;
}
.recent-order__totals__row:first-child {
  padding-top: 0;
  margin-top: 0;
  border-top: 0;
}
.recent-order__totals__row span {
  float: right;
}
@media screen and (max-width: 720px) {
  .order .order__column {
    width: 35%;
    margin-top: 12px;
  }
  .order .order__column--image {
    width: 30%;
    margin-top: 0;
  }
  .order .order__column--image img {
    width: 100%;
  }
  .order .order__column--name {
    width: 70%;
    margin-top: 0;
  }
  .order .order__column--price {
    float: left;
  }
  .order .order__created {
    width: 50%;
  }
  .order .order__created b {
    display: none;
  }
  .order .order__created i {
    margin-left: 0;
  }
  .order .order__created > span:first-child {
    padding: 0;
    margin: 0 0 15px;
    display: block;
    border: 0;
  }
  .order .button--mini {
    background: #000;
    color: #fff;
    cursor: pointer;
  }
}
@media screen and (min-width: 720px) {
  .recent-order .recent-order__row > div:not(.recent-order__image) {
    margin-top: 17px;
  }
}
@media screen and (max-width: 720px) {
  .recent-order .recent-order__row .recent-order__image {
    width: 30%;
    padding-right: 15px;
    margin-top: 0;
  }
  .recent-order .recent-order__row .recent-order__name {
    width: 70%;
    margin-top: 0;
  }
  .recent-order .recent-order__row .recent-order__size {
    width: 35%;
    margin-top: 10px;
  }
  .recent-order .recent-order__row .recent-order__quantity {
    width: 35%;
    text-align: right;
    margin-top: 10px;
  }
  .recent-order .recent-order__row .recent-order__total {
    float: left;
    margin-top: 10px;
  }
  .recent-order .recent-order__address__header,
  .recent-order .recent-order__card__header {
    font-size: 13px;
    padding: 10px;
    font-weight: 400;
  }
}
