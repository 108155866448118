@import '../_functions.less';
@import '../_variables.less';
/** styles/components/_blog.less **/
.blog{
	.clearfix();
	&__header{
		background: #000;
		background-size: 80px;
		height: 250px;
		color: #fff;
		position: relative;
		h1{
			font-size: 25px;
			position: absolute;
			transform: translate3d(-50%, 0%, 0);
			left: 50%;
			letter-spacing: 5px;
			font-weight: 500;
			margin-top: 75px;
		}
	}
	&__container{
		max-width: 800px;
		margin: -80px auto 0;
		position: relative;
	}
	&__recent-post{
		position: relative;
		background: #fff;
		box-shadow: 2px 2px 10px -4px #c7c7c7;
		overflow: hidden;
		display: block;
		text-decoration: none;
		color: inherit;
		min-height: 300px !important;
		&:hover{
			.blog__recent-post__content{
				margin-top: -160px;
			}
		}
		h3{
			margin-bottom: @spacing-small;
		}
		&__content{
			padding: @spacing-base;
			margin-top: -76px;
			background: #fff;
			position: relative;
			transition: all .25s ease;
			i{
				margin-right: @spacing-xs;
			}
			p{
				margin-bottom: 0;
				margin-top: @spacing-small;
				height: 72px;
			}
		}
		&__sub-header{
			color: #999;
		}
		&__tag{
			position: absolute;
			top: @spacing-xs;
			right: @spacing-xs;
			color: #fff;
			padding: @spacing-xs 7px @spacing-xs @spacing-small;
			z-index: 9;
			border-radius: 3px;
			font-size: 11px;
			text-transform: uppercase;
			letter-spacing: 3px;
		}
		&--no-slide{
			&:hover{
				.blog__recent-post__content{
					margin-top: -76px;
				}
			}
			.blog__recent-post__content{
				position: absolute;
			    bottom: 0;
			    margin-top: 0;
			    width: 100%;
			}
		}
	}
	&__section{
		display: block;
		width: 100%;
		padding: @spacing-large;
		background: #fff;
		margin: @spacing-large 0;
		.clearfix();
	}
	&__products{
		background: #fff;
		margin: @spacing-xl 0;
		padding: @spacing-large @spacing-xl;
		.wrap__row{
			margin: 0;
		}
		.wrap__header{
			max-width: 300px;
		}
		.grid{
			margin-bottom: 0;
		}
	}
	&__main-upload{
		border: 4px dashed @color-blue;
		min-height: 450px;
		margin: @spacing-base;
		position: relative;
		background: #fff;
		display: block;
		max-height: 450px;
		overflow: hidden;
		margin-bottom: @spacing-large;
		i{
			position: absolute;
			transform: translate3d(-50%, -50%, 0);
			top: 50%;
			left: 50%;
			font-size: 60px;
			color: @color-blue;
		}
		input{
			display: none;
		}
	}
	&__main-image{
		max-height: 450px;
		overflow: hidden;
		margin-bottom: @spacing-large;
		position: relative;
		&__content{
			position: absolute;
			bottom: 0;
			right: 0;
			color: #fff;
			padding: @spacing-large;
			background: rgba(0, 0, 0, 0.5);
			h3{
				margin-bottom: 0;
			}
			i{
				margin-right: @spacing-small;
			}
		}
	}
	&__share-buttons{
		position: absolute;
		bottom: 0;
		a{
			padding: @spacing-large;
			cursor: pointer;
			display: block;
			color: #fff;
			float: left;
		}
		&__button--facebook{
			background: @facebook-color;
		}
		&__button--twitter{
			background: @twitter-color;
		}
		&__button--is-live{
			background: @color-success;
		}
		&__button--is-not-live{
			background: @color-danger;
		}
	}
	&__post{
		position: relative;
		label + h3{
			margin-top: @spacing-large;
		}
		h3 {
			font-size: 22px;
		}
		p{
			font-size: 18px;
			max-width: 650px;
			margin-left: auto;
			margin-right: auto;
			text-align: justify;
		}
		img{
			min-height: 150px;
			max-width: 450px;
			display: block;
			margin: @spacing-small auto;
		}
		&__title{
			text-align: center;
			outline: none;
			background: #000;
			padding: @spacing-base @spacing-small;
			color: #fff;
			margin: 0;
			position: relative;
			left: 50%;
			max-width: 580px;
			margin-left: -290px;
		}
		&__content{
			background: #fff;
			padding: @spacing-large;
			min-height: 400px;
			text-align: center;
			iframe{
				margin: 0 auto;
				max-width: initial;
			}
		}
		&__user{
			padding-top: @spacing-base;
			margin-top: @spacing-large;
			border-top: 1px solid @color-border;
			img{
				height: 150px;
				width: 150px;
				border-radius: 90px;
				border: 1px solid @color-border;
				box-shadow: 1px 3px 10px 0px #d9d9d9;
			}
		}
		&__image-upload,
		&__embed{
			min-height: 150px;
			max-width: 450px;
			border: 3px dashed @color-blue;
			display: block;
			margin: @spacing-small auto;
			position: relative;
			cursor: pointer;
			i{
				position: absolute;
				transform: translate3d(-50%, -50%, 0);
				top: 50%;
				left: 50%;
				font-size: 30px;
				color: @color-blue;
			}
			input{
				display: none;
			}
			&--square{
				margin: 15px auto 0;
				height: 350px;
				width: 350px;
				img{
					width: 100%;
					height: 100%;
					max-width: initial;
					margin: 0;
				}
			}
		}
		&__embed{
			min-height: 230px;
			i{
				transform: translate3d(-50%, -130%, 0);
			}
			input{
				display: block;
				border: 0;
				border-top: 3px dashed @color-blue;
				position: absolute;
				bottom: 0;
				left: 0;
				background: @color-grey-dark;
			}
			&--has-content{
				max-width: initial;
			}
		}
		&__buttons{
			position: fixed;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			bottom: 0;
			z-index: 1;
		}
		& &__button{
			padding: @spacing-base;
			float: left;
			background: #000;
			color: #fff;
			cursor: pointer;
			&:hover{
				background: #222;
			}
			&--input{
				background: #fff;
				border: 1px solid #000;
				font-weight: 300;
				color: #444;
				width: 230px;
				float: right;
				&:hover{
					background: #fff;
				}
			}
		}
	}
	&__row{
		position: relative;
		&:hover{
			background: #FFFFE0;
			.blog__row__options{
				display: block;
				height: 40px;
			}
		}
		&__options{
			position: absolute;
			transform: translate3d(-50%, 0, 0);
			left: 50%;
			top: -36px;
			display: none;
			a{
				padding: @spacing-xs @spacing-small;
				margin-left: @spacing-xs;
				float: left;
				background: @color-blue;
				border: 1px solid @color-blue-dark;
				line-height: 0;
				color: #fff;
				border-radius: 3px;
				cursor: pointer;
				box-shadow: 0px 5px 15px -2px #999;
				&:first-child{
					margin-left: 0;
				}
				&[data-type="delete"]{
					background: @color-danger;
					border-color: @color-danger-dark;
				}
			}
		}
	}
	&__tooltip{
		float: left;
		position: relative;
		&__content{
			position: absolute;
			bottom: 22px;
			background: #000;
			color: #fff;
			border-radius: 2px;
    		width: 116px;
			left: 50%;
			transform: translateX(-50%);
			font-size: @font-size-small;
			text-align: left;
			z-index: 1;
			.arrow(@spacing-small, #000, top, 50%, 0px, #000);
		}
		&__link{
			float: left;
			padding: @spacing-base;
			cursor: pointer;
			-webkit-appearance: none;
			background: transparent;
			color: #fff;
			border: 0;
			outline: none;
			&:hover{
				background: #222;
			}
			i{
				pointer-events: none;
			}
		}
	}
	&__comments{
		background: #fff;
		padding: @spacing-base @spacing-large;
	}
}

//responsive
@media screen and (max-width: @screen-size-medium){
	.blog{
		&__post{
			&__title{
				width: 95%;
				margin: 0 auto;
				left: 0;
			}
			&__content__embed{
				width: 100% !important;
			}
		}
	}
}