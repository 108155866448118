.home-image {
  position: relative;
}
.home-image img {
  display: block;
  width: 100%;
}
.home-image__header {
  position: absolute;
  bottom: -24px;
  padding: 15px 10px 15px 15px;
  font-size: 15px;
  background: #000;
  margin: 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 5px;
  font-weight: 300;
  transform: translateX(-50%);
  left: 50%;
  text-align: center;
}
@media screen and (max-width: 450px) {
  .home-image .home-image__header {
    width: 80%;
  }
}
.wrap--home .video {
  width: 800px;
  height: 450px;
}
@media screen and (max-width: 450px) {
  .wrap--home .video {
    width: 380px;
    height: 220px;
  }
}
.reveal-item a {
  margin-left: 5px;
  color: blue;
  cursor: pointer;
}
.reveal-item a:hover {
  text-decoration: underline;
}
