html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat';
  background: #fff;
  font-size: 14px;
  color: #222;
  font-weight: 300;
}
html *,
body * {
  box-sizing: border-box;
}
html.checkout,
body.checkout {
  background: #f9f9f9;
}
body.no-scroll {
  overflow-y: hidden;
}
body.blog {
  background: #f9f9f9;
}
h1,
h2,
h3,
h4 {
  text-transform: uppercase;
  margin-top: 0;
}
form {
  margin: 0;
}
ul {
  font-weight: 300;
  padding-left: 15px;
  margin: 15px 0 15px 15px;
}
select,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"] {
  width: 100%;
  padding: 15px;
  -webkit-appearance: none;
  border-radius: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  font-family: 'Montserrat';
  border: 1px solid #a6a6a6;
}
input[type="date"] {
  padding: 8.5px!important;
}
input[type="text"][size] {
  width: auto;
}
input[type="password"] {
  letter-spacing: 5px;
}
textarea {
  width: 100%;
  padding: 15px;
  -webkit-appearance: none;
  border-radius: 0;
  cursor: pointer;
  font-weight: 300;
  border: 1px solid #a6a6a6;
  font-size: 13px;
  font-family: 'Montserrat';
  min-height: 230px;
  max-width: 100%;
}
iframe {
  width: 100%;
  display: block;
}
cite {
  display: inline-block;
  padding: 2px 8px;
  background: #f9f9f9;
  border-radius: 6px;
  font-style: normal;
  border: 1px solid #e0e0e0;
  font-size: 11px;
  pointer-events: none;
}
cite i {
  pointer-events: none;
  margin-right: 5px;
}
a.image-link {
  text-decoration: none;
}
a.image-link h3 {
  color: #000;
  margin-top: 10px;
  margin-bottom: 0;
}
.link {
  font-style: italic;
  font-size: 14px;
  cursor: pointer;
}
.link:hover {
  text-decoration: none;
}
.link--grey {
  color: #7a7a7a;
}
.link--underline {
  text-decoration: underline;
}
.social {
  width: 95px;
  margin: 0 auto;
}
.social:after {
  content: '';
  display: block;
  clear: both;
}
.social a {
  float: left;
  text-decoration: none;
  color: #000;
  border: 1px solid #000;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 30px;
  margin-left: 15px;
}
.social a:first-child {
  margin-left: 0;
}
.social a:hover {
  background: #000;
  color: #fff;
}
.social i {
  margin-top: 12px;
  display: block;
}
.select {
  position: relative;
}
.select:after {
  content: '\f0d7';
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'FontAwesome';
}
.select--small select {
  padding: 10px;
  font-weight: 300;
  padding-right: 25px;
}
.select--small:after {
  right: 10px;
}
b {
  font-weight: 500;
}
b i {
  margin-right: 10px;
}
img {
  width: 100%;
  display: block;
}
p {
  font-weight: 300;
}
p.center {
  text-align: center;
}
.button-container {
  margin-top: 15px;
}
.wrap {
  width: 95%;
  max-width: 980px;
  margin: 0 auto;
}
.wrap:after {
  content: '';
  display: block;
  clear: both;
}
.wrap__row {
  margin-bottom: 60px;
}
.wrap__row:first-child {
  margin-top: 60px;
}
.wrap__row--small {
  margin-bottom: 15px;
}
.wrap__header {
  text-align: center;
  padding: 15px 10px 15px 15px;
  color: #fff;
  max-width: 200px;
  margin: 0 auto 15px;
  font-size: 20px;
  font-weight: 300;
  background: #000;
  letter-spacing: 5px;
}
.wrap--home .wrap__row:first-child {
  margin-top: 0;
}
.wrap--small {
  width: 50%;
}
@media screen and (max-width: 720px) {
  .wrap {
    width: 95%;
  }
  .wrap .wrap__row {
    margin-top: 10px;
  }
  .wrap .wrap__header {
    text-align: center;
    min-width: 95%!important;
  }
}
.map {
  position: relative;
}
.map__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.map__overlay--darker {
  background: rgba(0, 0, 0, 0.8);
}
.map__details {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: #fff;
  font-size: 18px;
  width: 100%;
}
.map__details i {
  font-size: 65px;
  margin-bottom: 15px;
}
.text-center {
  text-align: center;
}
