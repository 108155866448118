.button {
  padding: 15px;
  display: block;
  background: #000;
  float: left;
  text-align: center;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  -webkit-appearance: none;
  border: 0;
  height: 44px;
  outline: none;
  line-height: normal;
  font-family: inherit;
  border-radius: 0;
}
.button i {
  margin-right: 10px;
}
.button--large {
  min-width: 200px;
}
.button--center {
  float: none;
  max-width: 200px;
  margin: 0 auto;
  font-weight: bold;
}
.button--loader {
  position: relative;
}
.button--loader:after {
  content: 'Adding...';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: inherit;
  font-size: 14px;
  line-height: 3.2;
  pointer-events: none;
  cursor: wait;
}
.button--warning {
  background: #e74c3c;
}
.button--success {
  background: #27ae60;
  font-weight: 500;
}
.button--white {
  background: #fff;
  color: #000;
}
.button--outline {
  background: transparent;
  border: 1px solid #000;
  color: #000;
}
.button--outline:hover {
  background: #000;
  color: #fff;
}
.button--50 {
  width: 50%;
}
.button.button--right {
  float: right;
}
.button--mini {
  font-size: 11px;
  float: right;
  padding: 3px 8px;
  cursor: pointer;
  background: #e0e0e0;
  border-radius: 3px;
  height: auto;
  text-transform: none;
  color: inherit;
}
.button--social-facebook {
  background: #3b5998;
  float: left;
  min-width: auto;
  padding: 15px 30px;
  font-size: 15px;
  font-family: 'FontAwesome';
}
.button--social-facebook:before {
  content: '\f09a';
}
.button--social-twitter {
  background: #4099ff;
  float: left;
  min-width: auto;
  padding: 15px 30px;
  font-size: 15px;
  font-family: 'FontAwesome';
}
.button--social-twitter:before {
  content: '\f099';
}
.button--center {
  float: none;
  width: 200px;
  margin: 0 auto;
}
.button--absolute-right {
  position: absolute;
  right: 0;
}
.button[disabled] {
  background: #f9f9f9;
  color: #7a7a7a;
  cursor: not-allowed;
}
