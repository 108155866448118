/** pages/_faqs.less **/
.faqs .wrap__header {
  max-width: 100%;
  width: 430px;
}
.faqs h3 {
  margin-bottom: 10px;
  margin-top: 0;
}
.faqs p {
  margin-top: 0;
  margin-bottom: 30px;
}
.faqs__payments {
  padding: 0;
  margin: 0;
  list-style: none;
}
.faqs__payments li {
  margin-bottom: 5px;
}
.faqs__payments li:after {
  content: '';
  display: block;
  clear: both;
}
.faqs__payments img {
  float: left;
  width: 35px;
  margin-right: 10px;
}
.faqs__payments span {
  float: left;
  margin-top: 3px;
}
