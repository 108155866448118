.instagram-feed {
  position: relative;
  min-height: 180px;
  background: #999;
}
.instagram-feed:after {
  content: '';
  display: block;
  clear: both;
}
.instagram-feed:hover .instagram-feed__overlay {
  background: rgba(0, 0, 0, 0.4);
}
.instagram-feed__logo {
  position: absolute;
  font-size: 40px;
  color: #fff;
  z-index: 1;
  line-height: 1;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
}
.instagram-feed__logo i {
  padding: 15px 18px;
  border: 5px solid;
  border-radius: 50px;
}
.instagram-feed__logo span {
  display: block;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 300;
}
.instagram-feed a {
  display: block;
}
.instagram-feed__images a {
  float: left;
  width: 20%;
  line-height: 0;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 720px) {
  .instagram-feed__images a {
    width: 33.33%;
  }
}
.instagram-feed__images a img {
  width: auto;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.instagram-feed__overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  transition: all .25s ease;
}
.instagram-feed__show-more {
  position: absolute;
  bottom: 15px;
  right: 15px;
  font-weight: 500;
  font-size: 11px;
}
