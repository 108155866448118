@import '../_functions.less';
@import '../_variables.less';

.terms{
	h4{
		margin-bottom: @spacing-xs;
	}
	p{
		margin-top: 0;
		margin-bottom: @spacing-large;
	}
	.wrap__header.terms{
		min-width: 320px;
	}
	.wrap__header.business{
		min-width: 380px;
	}
}