/** _modal.less **/
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(0);
  background: #fff;
  width: 530px;
  max-width: 95%;
  z-index: 202;
  transition: transform .0s ease;
  max-height: 100vh;
}
.modal--active {
  transform: translate3d(-50%, -50%, 0) scale(1);
  transition: transform .25s ease;
}
.modal--large {
  width: 75%;
}
.modal__header {
  padding: 15px;
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
}
.modal__row {
  margin-top: 15px;
}
.modal__row b {
  margin-bottom: 10px;
  display: block;
}
.modal__sub-header {
  background: #fafafa;
  padding: 15px;
  border-bottom: 1px solid #eee;
}
.modal__sub-header:after {
  content: '';
  display: block;
  clear: both;
}
.modal__sub-header img {
  float: left;
  margin-left: 10px;
  width: 30px;
}
.modal__sub-header img:first-child {
  margin-left: 0;
}
.modal__sub-header i {
  float: right;
  color: #27ae60;
  font-size: 20px;
}
.modal__close--top {
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 14px;
  padding: 10px;
  background: #e74c3c;
  border: 1px solid #c0392b;
  color: #fff;
  border-radius: 30px;
  text-align: center;
  font-size: 11px;
  line-height: 1;
  z-index: 1;
}
.modal__close--top i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 9px;
}
.modal__content {
  padding: 15px;
}
.modal__buttons:after {
  content: '';
  display: block;
  clear: both;
}
.modal__buttons a {
  float: right;
}
.modal__buttons a:first-child:not(.button--right) {
  float: left;
}
.modal__buttons--padding {
  padding: 15px;
  border-top: 1px solid #eee;
}
@media screen and (max-width: 450px) {
  .modal {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .modal .modal__header {
    font-size: 13px;
  }
}
