@import '../_functions.less';
@import '../_variables.less';
.used-cards{
	//blocks
	& &__card{
		border: 1px solid @color-border;
		padding: @spacing-base;
		position: relative;
		//fuctions calls
		.clearfix();
		//child blocks
		b{
			display: block;
			margin-bottom: @spacing-xs;
		}
		&__type{
			position: absolute;
			right: @spacing-base;
			top: @spacing-base;
			width: 35px;
		}
		&__number{
			span{
				letter-spacing: 5px;
			}
		}
		// child blocks
		.grid{
			margin-top: @spacing-base;
		}
	}
}