@import '../_functions.less';
@import '../_variables.less';
.user{
	& &__points{
		text-align: center;
		//functions
		.clearfix();
		//elements
		h4{
			margin-bottom: @spacing-base;
		}
		&__description{
			margin-bottom: @spacing-base;
		}
		&__amount{
			font-size: 40px;
			padding: @spacing-large;
			border: 2px solid #000;
			border-radius: 100px;
			line-height: 1;
			display: inline-block;
			margin-bottom: @spacing-small;
			width: 104px;
			position: relative;
			&--large{
				width: 155px;
				padding: 55px @spacing-large;
			}
		}
		&__buttons{
			position: absolute;
			font-size: 10px;
			.positionElem(-50%, -50%, 50%, -35px);
			a{
				padding: @spacing-small;
				display: block;
				background: @color-blue;
				color: #fff;
				border-radius: @spacing-large;
				position: relative;
				cursor: pointer;
				border: 1px solid @color-blue-dark;
				i{
					.positionElem(-50%, -50%, 50%, 50%);
				}
				&.user__points__increment{
					margin-bottom: @spacing-xs;
				}
			}
		}
	}
	& &__info{
		border: 1px solid @color-border;
		margin-bottom: @spacing-base;
		.clearfix();
		//child elements
		&__column{
			float: left;
			padding: @spacing-base;
			//children
			b{
				display: block;
				margin-bottom: @spacing-xs;
			}
		}
		//modifiers
		&--big{
			.user__info__column{
				width: 25%;
			}
		}
	}
	&__image-uploader{
		height: 150px;
		width: 150px;
		border: 3px dashed @color-blue;
		display: block;
		margin: @spacing-small auto;
		position: relative;
		cursor: pointer;
		border-radius: 100px;
		overflow: hidden;
		i{
			position: absolute;
			transform: translate3d(-50%, -50%, 0);
			top: 50%;
			left: 50%;
			font-size: 30px;
			color: @color-blue;
		}
		input{
			display: none;
		}
	}
}