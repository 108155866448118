@import '../_functions.less';
@import '../_variables.less';
.off-screen{
	position: fixed;
	top: 0;
	width: 85%;
	background: #fff;
	z-index: 300;
	height: 100%;
	transform: translate3d(-100%,0,0);
	transition: all .25s ease;
	ul{
		margin: 0;
		padding: 0;
		li{
			list-style: none;
			border-bottom: 1px solid @color-border;
			a{
				padding: @spacing-base;
				display: block;
				color: inherit;
				text-decoration: none;
			}
		}
	}
	&__header{
		margin: 0;
		padding: @spacing-base;
		background: #000;
		color: #fff;
		font-weight: 300;
	}
	&__sub-nav-li{
		&:hover{
			.off-screen__sub-nav{
				display: block;
			}
		}
		span{
			display: block;
			padding: @spacing-base;
		}
	}
	&__sub-nav{
		display: none;
		background: @color-grey-light;
		border-top: 1px solid @color-border;
	}
	//modifiers
	&--active{
		transform: translate3d(0%,0,0);
	}
}