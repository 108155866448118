.used-cards .used-cards__card {
  border: 1px solid #eee;
  padding: 15px;
  position: relative;
}
.used-cards .used-cards__card:after {
  content: '';
  display: block;
  clear: both;
}
.used-cards .used-cards__card b {
  display: block;
  margin-bottom: 5px;
}
.used-cards .used-cards__card__type {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 35px;
}
.used-cards .used-cards__card__number span {
  letter-spacing: 5px;
}
.used-cards .used-cards__card .grid {
  margin-top: 15px;
}
