/** styles/components/_user-block.less **/
.user-block {
  margin-bottom: 15px;
}
.user-block:after {
  content: '';
  display: block;
  clear: both;
}
.user-block__image {
  width: 65px;
  float: left;
  margin-right: 15px;
}
.user-block__content__name {
  font-weight: 500;
}
.user-block__content__email {
  font-style: italic;
  color: #7a7a7a;
}
.user-block__content__sign-out {
  margin-top: 5px;
}
.user-block__content__sign-out a {
  color: #7a7a7a;
  font-style: italic;
  text-decoration: none;
}
.user-block__content__sign-out a:hover {
  text-decoration: underline;
}
