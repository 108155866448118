.show-case__header {
  width: 350px;
  margin: 120px auto 0;
}
.show-case__section {
  width: 100%;
  margin: 60px 0 0;
}
.show-case__section h3 {
  max-width: 340px;
  margin-bottom: 30px;
}
.show-case__section p {
  margin-bottom: 30px;
  line-height: 2;
  margin-top: 0;
}
.show-case__buttons {
  width: 100%;
  margin: 0 auto;
}
.show-case__buttons:after {
  content: '';
  display: block;
  clear: both;
}
.show-case__buttons a {
  margin-left: 3%;
}
.show-case__buttons a:first-child {
  margin-left: 0;
}
.show-case__buttons .button--outline {
  float: left;
  min-width: 55%;
}
.show-case__buttons .button--social-facebook,
.show-case__buttons .button--social-twitter {
  width: 18%;
  padding: 15px 0;
}
