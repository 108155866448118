@import '../_functions.less';
@import '../_variables.less';

.landing-page {
	position: relative;
	&__bg {
		height: 100vh;
		width: auto;
		margin: 0 auto;
	}
	&__logo {
		width: 135px;
		margin: 0 auto 60px;
	}
	&__signup {
		position: absolute;
		transform: translate3d(-50%, -50%, 0);
		top: 50%;
		left: 50%;
		color: #fff;
		width: 90%;
		max-width: 520px;
		text-shadow: 0px 0px 10px #000;
		&__header {
			letter-spacing: 5px;
			text-align: center;
		}
		&__subheader {
			text-align: center;
			margin-bottom: @spacing-base;
			display: block;
		}
	}
}