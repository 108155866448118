@import '../_functions.less';
@import '../_variables.less';
header{
	position: relative;;
	background: #fff;
	border-bottom: 1px solid #eee;
	z-index: 100;
	position: relative;
	position: fixed;
	top: 0;
	width: 100%;
	.clearfix();
}
.header-fix{
	height: 45px;
}
/* block */
.nav{
	list-style: none;
	padding: 0;
	margin: 0;
	font-weight: 500;
	.clearfix();
	li{
		float: left;
		text-transform: uppercase;
		font-size: 12px;
		a{
			display: block;
			padding: 15px;
			text-decoration: none;
			color: inherit;
			&:hover, &:focus{
				background: #f9f9f9;
			}
		}
		i{
			margin-right: 10px;
		}
	}
	/* elements */
	&__logo{
		width: 38px;
		position: absolute;
		left: 50%;
		margin-left: -19px;
		margin-top: -6px;
		top: 50%;
		div{
			background: #000;
			height: 10px;
			width: 10px;
			margin-left: 4px;
			float: left;
			transform: rotate(45deg);
			&:first-child{
				margin-left: 0;
			}
		}
	}
	& &__sub-li{
		display: block;
		padding: @spacing-base;
		min-width: 130px;
		&:hover,
		&:focus,
		&:active{
			+ .nav__overlay{
				display: block;
			}
			.nav__sub-nav{
				display: block;
			}
		}
		span > a {
			margin: -@spacing-base;
			text-align: center;
		}
		&--list{
			position: relative;
			&:hover, &:focus{
				background: #000;
				color: #fff;
			}
			.nav__sub-nav{
				background: #000;
				width: 100%;
				left: 0;
				&__item{
					border-top: 1px solid #222;
					text-transform: capitalize;
					padding: 0;
					a{
						padding: @spacing-base;
						display: block;
						&:hover{
							background: #222;
						}
					}
				}
			}
		}
	}
	& &__sub-nav{
		position: absolute;
		top: 45px;
		background: #fff;
		z-index: 100;
		display: none;
		&--collections{
			width: 100%;
			left: 0;
			border-top: 1px solid @color-border;
		}
		&__item{
			padding: @spacing-base;
			img{
				width: 250px;
				margin: 0 auto;
			}
			a{
				padding: 0;
				display: initial;
				.clearfix();
				&:hover{
					background: #fff;
				}
			}
		}
	}
	&__overlay{
		position: fixed;
		background: rgba(0,0,0,0.9);
		height: 100%;
		width: 100%;
		left: 0;
		top: 45px;
		display: none;
	}
	/* modifiers */
	&--left{
		float: left;
	}
	&--right{
		float: right;
	}
	&--mobile{
		display: none;
	}
}
//responsive
@media screen and (max-width: @screen-size-medium){
	header{
		display: none;
	}
	header.nav--mobile{
		display: block;
	}
	.nav{
		li {
			i{
				margin-right: 0;
				font-size: 14px;
			}
			a{
				&:hover{
					color: #000;
					background: #fff;
				}
			}
		}
		&__logo{
			div{
				background: #fff;
			}
		}
		//modifiers
		&--mobile{
			color: #fff;
			border-bottom: 0;
			background: #000;
		}
	}
}