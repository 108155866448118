@import '../_functions.less';
@import '../_variables.less';
.message{
	border: 1px solid @color-border;
	transition: all ease .25s;
	opacity: 1;
	&__header{
		padding: @spacing-base;
		background: #f9f9f9;
		border-bottom: 1px solid @color-border;
		&:before{
			content: '';
			margin-right: @spacing-small;
			font-family: 'FontAwesome';
		}
	}
	&__content{
		padding: @spacing-base;
	}
	&__close{
		position: absolute;
		top: -7px;
		right: -9px;
		font-size: 9px;
		background: @color-danger;
		border: 1px solid @color-danger-dark;
		line-height: 1;
		border-radius: 30px;
		height: 18px;
		width: 18px;
		cursor: pointer;
		i{
			float: left;
			margin: 3px 0 0 5px;
		}
	}
}

//modifiers
.message--global.message{
    position: fixed;
    width: auto;
    top: @spacing-base;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
	.message{
		&__header{
			border-bottom: 0;
		}
	}
	@media screen and (max-width: @screen-size-medium){
		width: 80%;
		text-align: center;
	}
	@media screen and (max-width: @screen-size-small){
		width: 95%;
	}
}
.message--info.message{
	border-color: @color-info-dark;
	//using for structer
	.message{
		&__header{
			background: @color-info;
			border-color: @color-info-dark;
			color: #fff;
			&:before{
				content: '\f05a';
			}
		}
	}
}
.message--success.message{
	border-color: @color-success-dark;
	.message{
		&__header{
			background: @color-success;
			border-color: @color-success-dark;
			color: #fff;
			&:before{
				content: '\f00c';
			}
		}
	}
}
.message--error.message{
	border-color: @color-danger-dark;
	.message{
		&__header{
			background: @color-danger;
			border-color: @color-danger-dark;
			color: #fff;
			&:before{
				content: '\f1cd';
			}
		}
	}
}
.message--warning.message{
	border-color: @color-warning-dark;
	.message{
		&__header{
			background: @color-warning;
			border-color: @color-warning-dark;
			color: #fff;
			&:before{
				content: '\f071';
			}
		}
	}
}
.message--small.message{
	.message{
		&__header{
			background: transparent;
			color: @color-info-dark;
			border-bottom: 0;
			padding: @spacing-small;
			text-align: center;
		}
	}
}
.message--hide.message{
	opacity: 0;
	top: 0;
	pointer-events: none;
}