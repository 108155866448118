@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/** checkout-cart.less **/
.checkout-cart {
  background: #fff;
  border: 1px solid #eee;
}
.checkout-cart__header {
  padding: 15px;
  margin-bottom: 0;
  border-bottom: 1px solid #eee;
}
.checkout-cart__sub-header {
  background: #fafafa;
  padding: 15px;
  border-bottom: 1px solid #eee;
}
.checkout-cart__sub-header:after {
  content: '';
  display: block;
  clear: both;
}
.checkout-cart__sub-header img {
  float: left;
  margin-left: 10px;
  width: 30px;
}
.checkout-cart__sub-header img:first-child {
  margin-left: 0;
}
.checkout-cart__sub-header i {
  float: right;
  color: #27ae60;
  font-size: 20px;
}
.checkout-cart__content {
  padding: 15px;
  position: relative;
}
.checkout-cart__content:after {
  content: '';
  display: block;
  clear: both;
}
.checkout-cart__button--top {
  padding: 15px;
  margin: -15px -15px 15px;
  border-bottom: 1px solid #eee;
  font-weight: 500;
}
.checkout-cart__button--top a {
  color: inherit;
  text-decoration: none;
}
.checkout-cart__button--top i {
  margin-right: 5px;
}
.checkout-cart__quantity {
  float: right;
  font-size: 11px;
  font-weight: 300;
  background: #e74c3c;
  color: #fff;
  padding: 5px 9px;
  border-radius: 30px;
  margin-top: -3px;
  border: 1px solid #a82315;
}
.checkout-cart .checkout-cart__item {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #eee;
  position: relative;
}
.checkout-cart .checkout-cart__item:after {
  content: '';
  display: block;
  clear: both;
}
.checkout-cart .checkout-cart__item:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}
.checkout-cart .checkout-cart__item__image {
  float: left;
  width: 30%;
  padding-right: 3%;
  position: relative;
}
.checkout-cart .checkout-cart__item__remove {
  background: #e74c3c;
  position: absolute;
  top: 0px;
  left: 0;
  color: #fff;
  line-height: 0;
  padding: 5px 5px;
  border-radius: 0px;
  font-size: 11px;
  cursor: pointer;
}
.checkout-cart .checkout-cart__item__content {
  width: 70%;
  float: left;
}
.checkout-cart .checkout-cart__item__name {
  float: 70%;
  float: left;
}
.checkout-cart .checkout-cart__item__price {
  float: right;
  width: 30%;
  text-align: right;
}
.checkout-cart .checkout-cart__item__size {
  clear: both;
  margin-top: 10px;
}
.checkout-cart .checkout-cart__item__quantity {
  float: left;
  line-height: 1.5;
  margin-top: 10px;
}
.checkout-cart .checkout-cart__item__quantity:after {
  content: '';
  display: block;
  clear: both;
}
.checkout-cart .checkout-cart__item__decrement,
.checkout-cart .checkout-cart__item__increment {
  float: left;
  color: #2980b9;
  border: 1px solid #2980b9;
  padding: 6px 7px;
  font-size: 8px;
  border-radius: 18px;
  cursor: pointer;
  line-height: 1;
}
.checkout-cart .checkout-cart__item__decrement:hover,
.checkout-cart .checkout-cart__item__increment:hover {
  background: #e1f0fa;
}
.checkout-cart .checkout-cart__item__decrement[data-disabled="true"] {
  border-color: #e0e0e0;
  color: #e0e0e0;
  pointer-events: none;
  cursor: not-allowed;
}
.checkout-cart .checkout-cart__item__qty {
  float: left;
  padding: 0 10px;
}
.checkout-cart__loader-container--active:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;
}
.checkout-cart__loader-container--active .checkout-cart__loader {
  margin: 15px auto;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 0.3em solid #3498db;
  border-right: 0.3em solid #3498db;
  border-bottom: 0.3em solid #3498db;
  border-left: 0.3em solid #e1f0fa;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -webkit-animation: loader 1.1s infinite linear;
  animation: loader 1.1s infinite linear;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -4em;
  margin-left: -4em;
  z-index: 2;
}
.checkout-cart__loader-container--active .checkout-cart__loader:after,
.checkout-cart__loader-container--active .checkout-cart__loader {
  border-radius: 50%;
  width: 4em;
  height: 4em;
}
.checkout-cart__loader-container--active .checkout-cart__loader.tiny:after,
.checkout-cart__loader-container--active .checkout-cart__loader.tiny {
  border-radius: 50%;
  width: 4em;
  height: 4em;
}
.checkout-cart--info {
  text-align: center;
}
