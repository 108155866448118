@import '../_functions.less';
@import '../_variables.less';
.overlay{
	&--active{
		position: fixed;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.9);
		z-index: 200;
		top: 0;
	}
}