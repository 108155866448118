@import '../_functions.less';
@import '../_variables.less';
/** Payment Success **/
.payment-success{
	max-width: 700px;
	width: 100%;
	margin: 0 auto;
	h4{
		margin-bottom: @spacing-base;
	}
	&__reference{
		padding: @spacing-base;
		background: @color-success;
		margin-top: -1px;
		color: #fff;
		.clearfix();
		span{
			text-transform: uppercase;
			float: right;
		}
		@media screen and (max-width: @screen-size-small){
			span{
				font-size: 13px;
				float: left;
				margin-top: @spacing-xs;
			}
		}
	}
	&__header{
		background: @color-success-dark;
		color: #fff;
	}
	&__row{
		border-top: 1px solid @color-border;
		margin-top: @spacing-base;
		&:first-child{
			border-top: 0;
			margin-top: 0;
		}
		b{
			display: block;
			margin-bottom: @spacing-small;
		}
		//modifiers
		&--totals{
			background: @color-grey-light;
			padding: @spacing-base;
			margin: @spacing-base -@spacing-base -@spacing-base;
			h4{
				margin-bottom: 0;
				padding-bottom: @spacing-base;
				border-bottom: 1px solid @color-border;
			}
		}
	}
	&__total{
		padding: @spacing-base 0;
		border-bottom: 1px solid @color-border;
		span{
			float: right;
			font-weight: 500;
			i{
				margin-right: @spacing-xs;
			}
		}
		//modifiers
		&--last{
			padding: @spacing-base 0 0;
			border: 0;
		}
	}
	//modifiers
	&--center{
		text-align: center;
	}
	// child blocks
	.address{
		padding: 0;
		border: 0;
	}
}