.loader(@primary: #eee, @secondary: #000){
    margin: @spacing-base auto;
    font-size: 10px;
    position: absolute;
    text-indent: -9999em;
    border-top: 0.3em solid @primary;
    border-right: 0.3em solid @primary;
    border-bottom: 0.3em solid @primary;
    border-left: 0.3em solid @secondary;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    top: 50%;
    left: 50%;
    -webkit-animation: loader 1.1s infinite linear;
    animation: loader 1.1s infinite linear;
    &:after, &{
        border-radius: 50%;
        width: 4em;
        height: 4em;
    }
    &.tiny{
        &:after, &{
            border-radius: 50%;
            width: 4em;
            height: 4em;
        }
    }
}

.loader--secondary(@primary: #eee, @secondary: #000){
    margin: @spacing-base auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.3em solid @primary;
    border-right: 0.3em solid @primary;
    border-bottom: 0.3em solid @primary;
    border-left: 0.3em solid @secondary;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loader 1.1s infinite linear;
    animation: loader 1.1s infinite linear;
    &:after, &{
        border-radius: 50%;
        width: 4em;
        height: 4em;
    }
    &.tiny{
        &:after, &{
            border-radius: 50%;
            width: 4em;
            height: 4em;
        }
    }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
