@import '_functions.less';
@import '_variables.less';
.grid{
	margin: 3% -1.5%;
	.clearfix();
	&__item{
		float: left;
		padding: 0 1.5%;
		&--right{
			float: right;
		}
		&--1{
			width: 1%;
		}
		&--2{
			width: 16.6%
		}
		&--3{
			width: 25%;
			&:nth-child(4n+1){
				clear: left;
			}
			&:nth-child(1n+5){
				margin-top: @spacing-base;
			}
		}
		&--4{
			width: 33.33%;
			&:nth-child(3n+1){
				clear: left;
			}
			&:nth-child(1n+4){
				margin-top: @spacing-base;
			}
		}
		&--5{
			width: 41.6%;
		}
		&--6{
			width: 50%;
			&:nth-child(2n+1){
				clear: left;
			}
			&:nth-child(1n+3){
				margin-top: @spacing-base;
			}
		}
		&--7{
			width: 58.4%;
		}
		&--8{
			width: 66.6%;
		}
		&--9{
			width: 75.1%;
		}
		&--10{
			width: 83.3%;
		}
		&--11{
			width: 91.7%;
		}
		&--12{
			width: 100%;
		}
	}
	//modifier
	&--no-margin{
		margin-top: 0;
		margin-bottom: 0;
	}
	&--no-gutter{
		margin: 3% 0;
		//blockifying
		.grid{
			&__item{
				padding: 0;
			}
		}
	}
}

//responsive styles
@media screen and (max-width: @screen-size-medium){
	.grid{
		&__item{
			width: 100%;
			margin-top: @spacing-base;
			&:first-child{
				margin-top: 0;
			}
		}
		//modifiers
		&--two-column{
			//blockifying
			.grid{
				&__item{
					width: 50%;
					margin-top: 0;
				}
			}
		}
	}
}