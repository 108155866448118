@import '../_functions.less';
@import '../_variables.less';
.image-slider{
	overflow: hidden;
	position: relative;
	.clearfix();
	&:hover{
		cursor: -webkit-grab;
	}
	&__list{
		list-style: none;
		padding: 0;
		margin: 0;
		transition: all .25s ease-out;
		.clearfix();
		&--no-animation{
			transition: all 0s ease-out;
		}
	}
	&__image{
		float: left;
		&[data-type="video"]{
			background: #000;
		}
		img{
			pointer-events: none;
		}
		video{
			max-height: 630px;
			display: block;
			margin: 0 auto;
			width: 100%;
		}
	}
	&__buttons{
		a{
			position: absolute;
			padding: @spacing-small @spacing-base;
			text-decoration: none;
			transform: translate3d(0, -50%, 0);
			top: 50%;
			background: #000;
			color: #fff;
			i{
				line-height: 0;
			}
		}
	}
	&__next{
		right: 0;
	}
	&__left{
		left: 0;
	}
	&__dots{
		position: absolute;
		transform: translate3d(-50%, 0, 0);
		left: 50%;
		bottom: @spacing-base;
		a{
			margin-left: @spacing-base;
			background: #000;
			border: 2px solid #fff;
			border-radius: 30px;
			height: 20px;
			width: 20px;
			float: left;
			transition: all .25s ease-out;
			&:first-child{
				margin-left: 0;
			}
			&.image-slider__dot--active{
				background: #fff;
				transform: scale(1.2);
			}
		}
	}
}