@import '../_functions.less';
@import '../_variables.less';
/** styles/components/_form-selector.less **/
.form-selector{
	border: 1px solid @color-border;
	& &__item{
		display: block;
		width: 100%;
		padding: @spacing-base;
		border-top: 1px solid @color-border;
		cursor: pointer;
		.clearfix();
		&:first-child{
			border-top: 0;
		}
		&:hover{
			background: @color-grey-light;
		}
		span{
			float: right;
		}
	}
}