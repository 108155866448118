.address {
  float: left;
  width: 100%;
  padding: 15px;
  border: 1px solid #eee;
}
.address:after {
  content: '';
  display: block;
  clear: both;
}
.address .address__row {
  margin-top: 5px;
}
.address .address__row:first-child {
  margin-top: 0;
}
.address .address__row--bottom {
  background: #f9f9f9;
  border-top: 1px solid #eee;
  padding: 15px;
  margin: 15px -15px -15px;
}
.address .address__row--bottom:after {
  content: '';
  display: block;
  clear: both;
}
.address .address__row--right {
  float: right;
}
.address .address__row--right img {
  width: 30px;
}
.address__flag {
  float: left;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #2980b9;
}
.address__flag:first-child {
  margin-left: 0;
  padding-left: 0;
  border-left: 0;
}
.address__header {
  margin: -15px -15px 15px;
  padding: 10px 15px;
  background: #fff;
  font-weight: 500;
  border-bottom: 1px solid #2980b9;
  color: #2980b9;
}
.address--billing,
.address--shipping {
  border-color: #3498db;
}
.address--billing .address__row--bottom,
.address--shipping .address__row--bottom {
  color: #2980b9;
  border-color: #3498db;
  background: #e1f0fa;
}
.address--selectable {
  cursor: pointer;
}
.address--selectable:hover {
  border-color: #d5d5d5;
}
.address--selected {
  border-color: #2980b9;
  background: #f7fbfe;
  position: relative;
}
.address--selected:hover {
  border-color: #2980b9;
}
.address--selected:before {
  content: '\f00c';
  background: #2980b9;
  padding: 5px;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 1;
  font-family: 'FontAwesome';
  font-size: 8px;
  color: #fff;
}
.address .tooltip {
  float: right;
  line-height: 1;
}
