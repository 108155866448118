@import '../_functions.less';
@import '../_variables.less';
.breadcrumbs{
	text-align: center;
	.clearfix();
	& &__item{
		text-decoration: none;
		width: 100%;
		float: left;
		padding: @spacing-base;
		background: #fff;
		opacity: 0.5;
		border: 1px solid @color-grey-dark;
		pointer-events: none;
		color: inherit;
		border-top: 0;
		//modifiers
		&[data-selectable]{
			opacity: 1;
			pointer-events: initial;
			&:hover{
				background: @color-info-light;
				color: @color-info-dark;
				border-color: @color-info-dark;
			}
		}
		&[data-selected]{
			opacity: 1;
			background: @color-info-dark;
			color: #fff;
			border-color: @color-info-dark;
			pointer-events: none;
			&:hover{
				background: @color-info-dark;
				color: #fff;
			}
		}
	}
	//modifiers
	&--progress{
		display: none;
	}
}

//responsive
@media screen and (max-width: @screen-size-medium){
	.breadcrumbs{
		//modifiers
		&--progress{
			display: block;
			background: #fff;
			border: 1px solid @color-success-dark;
			margin-top: @spacing-base;
		}
		&__progress-bar{
			background: @color-success-dark;
			padding: @spacing-base;
			color: #fff;
			&[data-progress="0"]{
				width: 0%;
				padding: @spacing-base 0;
				&:before{
					content: 'Verify your cart';
				}
			}
			&[data-progress="1"]{
				width: 25%;
				color: @color-success-dark;
				&:before{
					content: '25% - Fill out your details';
				}
			}
			&[data-progress="2"]{
				width: 50%;
				&:before{
					content: '50% - Select shipping method';
				}
			}
			&[data-progress="3"]{
				width: 75%;
				&:before{
					content: 'Now pay!';
				}
			}
		}
		//child blocks
		.grid{
			display: none;
		}
	}
}