.user .user__points {
  text-align: center;
}
.user .user__points:after {
  content: '';
  display: block;
  clear: both;
}
.user .user__points h4 {
  margin-bottom: 15px;
}
.user .user__points__description {
  margin-bottom: 15px;
}
.user .user__points__amount {
  font-size: 40px;
  padding: 30px;
  border: 2px solid #000;
  border-radius: 100px;
  line-height: 1;
  display: inline-block;
  margin-bottom: 10px;
  width: 104px;
  position: relative;
}
.user .user__points__amount--large {
  width: 155px;
  padding: 55px 30px;
}
.user .user__points__buttons {
  font-size: 10px;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: -35px;
}
.user .user__points__buttons a {
  padding: 10px;
  display: block;
  background: #2980b9;
  color: #fff;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  border: 1px solid #20638f;
}
.user .user__points__buttons a i {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
}
.user .user__points__buttons a.user__points__increment {
  margin-bottom: 5px;
}
.user .user__info {
  border: 1px solid #eee;
  margin-bottom: 15px;
}
.user .user__info:after {
  content: '';
  display: block;
  clear: both;
}
.user .user__info__column {
  float: left;
  padding: 15px;
}
.user .user__info__column b {
  display: block;
  margin-bottom: 5px;
}
.user .user__info--big .user__info__column {
  width: 25%;
}
.user__image-uploader {
  height: 150px;
  width: 150px;
  border: 3px dashed #2980b9;
  display: block;
  margin: 10px auto;
  position: relative;
  cursor: pointer;
  border-radius: 100px;
  overflow: hidden;
}
.user__image-uploader i {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  font-size: 30px;
  color: #2980b9;
}
.user__image-uploader input {
  display: none;
}
