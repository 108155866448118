@import '../_functions.less';
@import '../_variables.less';
.card{
	&__row{
		margin-top: @spacing-xs;
		&:first-child{
			margin-top: 0;
		}
	}
	&__image{
		position: relative;
	}
	&__details{
		padding: @spacing-small;
		border: 1px solid @color-border;
		border-top: 0;
		background: @color-grey;
	}
	&__name{
		font-weight: 500;
	}
	&__price{
		float: right;
	}
	&__label{
		position: absolute;
		bottom: @spacing-base;
		right: 0;
		padding: @spacing-small;
		color: #fff;
		border-right: 0;
		text-decoration: none;
		font-size: 11px;
		transition: all .25s ease;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		i{
			margin-right: @spacing-xs;
		}
		&--warning{
			background: @color-danger;
		}
	}
	&__buttons{
		border: 1px solid @color-border;
		border-top: 0;
		.clearfix();
		a{
			padding: @spacing-small @spacing-base;
			float: left;
			&:hover{
				background: @color-grey;
			}
		}
		&__right{
			float: right;
			a{
				border-left: 1px solid @color-border;
			}
		}
		&__left{
			float: left;
			a{
				border-right: 1px solid @color-border;
			}
		}
	}
	&__button{
		color: @color-blue-dark;
		&--danger{
			color: @color-danger;
		}
	}
}