.message {
  border: 1px solid #eee;
  transition: all ease .25s;
  opacity: 1;
}
.message__header {
  padding: 15px;
  background: #f9f9f9;
  border-bottom: 1px solid #eee;
}
.message__header:before {
  content: '';
  margin-right: 10px;
  font-family: 'FontAwesome';
}
.message__content {
  padding: 15px;
}
.message__close {
  position: absolute;
  top: -7px;
  right: -9px;
  font-size: 9px;
  background: #e74c3c;
  border: 1px solid #c0392b;
  line-height: 1;
  border-radius: 30px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.message__close i {
  float: left;
  margin: 3px 0 0 5px;
}
.message--global.message {
  position: fixed;
  width: auto;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
}
.message--global.message .message__header {
  border-bottom: 0;
}
@media screen and (max-width: 720px) {
  .message--global.message {
    width: 80%;
    text-align: center;
  }
}
@media screen and (max-width: 450px) {
  .message--global.message {
    width: 95%;
  }
}
.message--info.message {
  border-color: #2980b9;
}
.message--info.message .message__header {
  background: #3498db;
  border-color: #2980b9;
  color: #fff;
}
.message--info.message .message__header:before {
  content: '\f05a';
}
.message--success.message {
  border-color: #1e8449;
}
.message--success.message .message__header {
  background: #27ae60;
  border-color: #1e8449;
  color: #fff;
}
.message--success.message .message__header:before {
  content: '\f00c';
}
.message--error.message {
  border-color: #c0392b;
}
.message--error.message .message__header {
  background: #e74c3c;
  border-color: #c0392b;
  color: #fff;
}
.message--error.message .message__header:before {
  content: '\f1cd';
}
.message--warning.message {
  border-color: #d35400;
}
.message--warning.message .message__header {
  background: #e67e22;
  border-color: #d35400;
  color: #fff;
}
.message--warning.message .message__header:before {
  content: '\f071';
}
.message--small.message .message__header {
  background: transparent;
  color: #2980b9;
  border-bottom: 0;
  padding: 10px;
  text-align: center;
}
.message--hide.message {
  opacity: 0;
  top: 0;
  pointer-events: none;
}
