@import '../_functions.less';
@import '../_variables.less';
.pages{
	overflow: hidden;
	&__page{
		float: left;
		display: none;
	}
	&__wrapper{
		transition: all .25s ease;
	}
}