@import '../_functions.less';
@import '../_variables.less';
.tooltip{
	float: left;
	position: relative;
	&__content{
		display: none;
		position: absolute;
		bottom: 22px;
		padding: @spacing-xs;
		background: #000;
		color: #fff;
		border-radius: 2px;
		width: 100px;
		left: 50%;
		transform: translateX(-50%);
		font-size: @font-size-small;
		text-align: left;
		.arrow(@spacing-xs, #000, bottom, 50%, 0px, #000);
		&--top{
			.arrow(@spacing-xs, #000, top, 50%, 0px, #000);
		}
		&__header{
			font-weight: 500;
			padding: @spacing-xs;
			margin: -@spacing-xs -@spacing-xs -1px;
			float: left;
			width: 100%;
			box-sizing: content-box;
			border-bottom: 1px solid #222;
		}
		&__row{
			display: block;
			border-top: 1px solid #222;
			padding: @spacing-xs;
			text-align: center;
			float: left;
			width: 100%;
		}
		&--center{
			text-align: center;
		}
	}
	&:hover{
		&:before{
			content: '';
			position: absolute;
			top: -8px;
			width: 200%;
			height: 18px;
			left: -50%;
		}
		//blockifying
		.tooltip{
			&__content{
				display: block;
			}
		}
	}
	//modifiers
	&--links{
		padding: 0;
		//blockifying
		.tooltip{
			&__link{
				padding: @spacing-small;
				float: left;
				width: 100%;
				border-top: 1px solid #222;
				cursor: pointer;
				line-height: 1.5;
				text-decoration: none;
				color: #fff;
				i{
					margin-right: @spacing-xs;
				}
				&:first-child{
					border-top: 0;
					&:hover{
						border-top-right-radius: 2px;
						border-top-left-radius: 2px;
					}
				}
				&:last-child:hover{
					border-bottom-right-radius: 2px;
					border-bottom-left-radius: 2px;
				}
				&:hover{
					background: #222;
				}
			}
		}
		button{
			border: none;
			outline: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			background: none;
			color: inherit;
			font-size: inherit;
			font-family: inherit;
			font-weight: inherit;
			text-align: left;
		}
	}
}