.pages {
  overflow: hidden;
}
.pages__page {
  float: left;
  display: none;
}
.pages__wrapper {
  transition: all .25s ease;
}
