.list {
  background: #f9f9f9;
}
.list--large {
  border: 1px solid #eee;
}
.list--large .list__item {
  padding: 30px;
  display: block;
  text-align: center;
  font-size: 30px;
  text-decoration: none;
  color: inherit;
  border-top: 1px solid #eee;
}
.list--large .list__item:hover {
  background: #2980b9;
  color: #fff;
  border-color: #20638f;
}
.list--large .list__item:first-child {
  border-top: 0;
}
