@import '../_functions.less';
@import '../_variables.less';
.address{
	float: left;
	width: 100%;
	padding: @spacing-base;
	border: 1px solid @color-border;
	.clearfix();
	& &__row{
		margin-top: @spacing-xs;
		&:first-child{
			margin-top: 0;
		}
		//modifiers
		&--bottom{
			background: @color-grey;
			border-top: 1px solid @color-border;
			padding: @spacing-base;
			margin: @spacing-base -@spacing-base -@spacing-base;
			.clearfix();
		}
		&--right{
			float: right;
			img{
				width: 30px;
			}
		}
	}
	&__flag{
		float: left;
		margin-left: @spacing-small;
		padding-left: @spacing-small;
		border-left: 1px solid @color-info-dark;
		&:first-child{
			margin-left: 0;
			padding-left: 0;
			border-left: 0;
		}
	}
	&__header{
		margin: -@spacing-base -@spacing-base @spacing-base;
		padding: @spacing-small @spacing-base;
		background: #fff;
		font-weight: 500;
		border-bottom: 1px solid @color-info-dark;
		color: @color-info-dark;;
	}
	//modifiers
	&--billing,
	&--shipping{
		border-color: @color-info;
		.address__row--bottom{
			color: @color-info-dark;
			border-color: @color-info;
			background: @color-info-light;
		}
	}
	&--selectable{
		cursor: pointer;
		&:hover{
			border-color: darken(@color-border, 10%)
		}
	}
	&--selected{
		border-color: @color-info-dark;
		background: lighten(@color-info, 45%);
		position: relative;
		// color: #fff;
		&:hover{
			border-color: @color-info-dark;
		}
		&:before{
			content: '\f00c';
			background: @color-info-dark;
			padding: @spacing-xs;
			position: absolute;
			right: 0;
			top: 0;
			line-height: 1;
			font-family: 'FontAwesome';
			font-size: @font-size-xs;
			color: #fff;
		}
	}
	//child blocks
	.tooltip{
		float: right;
		line-height: 1;
	}
}

.baner {
	ul{

	}
}