@import '../_functions.less';
@import '../_variables.less';
.order{
	margin-bottom: @spacing-base;
	.clearfix();
	&:first-of-type{
		padding-top: 0;
	}
	& &__content{
		padding: @spacing-base;
		border: 1px solid @color-border;
	}
	& &__row{
		margin-top: @spacing-small;
		padding-top: @spacing-small;
		border-top: 1px solid @color-border;
		.clearfix();
		&:first-child{
			margin-top: 0;
			padding-top: 0;
			border-top: 0;
		}
	}
	& &__column{
		float: left;
		margin-top: 25px;
		padding: 0 @spacing-small;
		&--image{
			margin-top: 0;
			padding: 0;
			width: 70px;
		}
		&--name{
			width: 35%;
		}
		&--size{
			width: 15%;
		}
		&--price{
			float: right;
		}
	}
	& &__top{
		margin-top: 0;
		padding: @spacing-base;
		background: @color-grey;
		border: 1px solid @color-border;
		border-bottom: 0;
		text-transform: uppercase;
		font-weight: 500;
		.clearfix();
	}
	& &__bottom{
		margin-top: 0;
		padding: @spacing-base;
		background: @color-grey;
		border: 1px solid @color-border;
		border-top: 0;
		.clearfix();
	}
	& &__created{
		float: left;
		b{
			margin-right: @spacing-small;
		}
		i{
			margin: 0 @spacing-xs 0 0;
		}
		> span:first-child{
			padding-right: @spacing-base;
			margin-right: @spacing-base;
			border-right: 1px solid @color-border;
		}
	}
	& &__more-info{
		float: right;
		text-decoration: none;
		color: inherit;
		i{
			margin-right: @spacing-xs;
		}
	}
}
.recent-order{
	background: @color-grey;
	& &__items{
		border: 1px solid @color-border;
		padding: @spacing-base;
		background: #fff;
		clear: both;
	}
	& &__row{
		margin-top: @spacing-base;
		padding-top: @spacing-base;
		border-top: 1px solid @color-border;
		.clearfix();
		&:first-child{
			margin-top: 0;
			padding-top: 0;
			border-top: 0;
		}
	}
	& &__image{
		width: 15%;
		padding-right: 15px;
		float: left;
	}
	& &__name{
		width: 35%;
		float: left;
	}
	& &__size{
		width: 15%;
		float: left;
	}
	& &__quantity{
		width: 5%;
		float: left;
	}
	& &__total{
		float: right;
	}
	& &__address,
	& &__card{
		background: #fff;
		border: 1px solid @color-border;
		&__header{
			padding: @spacing-base;
			margin-bottom: 0;
			border-bottom: 1px solid @color-border;
		}
		&__content{
			padding: @spacing-base;
			.clearfix();
		}
		&__row{
			line-height: 1.4;
		}
		img{
			width: 30px;
			float: right;
		}
	}
	& &__grand-total{
		padding: @spacing-base;
		margin: -@spacing-base -@spacing-base @spacing-base;
		background: #fff;
		border-bottom: 1px solid @color-border;
		span{
			float: right;
		}
	}
	&__totals{
		padding: @spacing-base;
		background: #000;
		color: #fff;
		&__row{
			padding-top: @spacing-base;
			margin-top: @spacing-base;
			border-top: 1px solid #222;
			&:first-child{
				padding-top: 0;
				margin-top: 0;
				border-top: 0;
			}
			span{
				float: right;
			}
		}
	}
}
//responsive
@media screen and (max-width: @screen-size-medium){
	.order{
		& &__column{
			width: 35%;
			margin-top: 12px;
			&--image{
				width: 30%;
				margin-top: 0;
				img{
					width: 100%;
				}
			}
			&--name{
				width: 70%;
				margin-top: 0;
			}
			&--price{
				float: left;
			}
		}
		& &__created{
			width: 50%;
			b{
				display: none;
			}
			i{
				margin-left: 0;
			}
			> span:first-child{
				padding: 0;
				margin: 0 0 @spacing-base;
				display: block;
				border: 0;
			}
		}
		//child blocks
		.button--mini{
			background: #000;
			color: #fff;
			cursor: pointer;
		}
	}
}
@media screen and (min-width: @screen-size-medium){
	.recent-order{
		& &__row{
			> div:not(.recent-order__image){
				margin-top: 17px;
			}
		}
	}
}
@media screen and (max-width: @screen-size-medium){
	.recent-order{
		& &__row{
			//blockifying
			.recent-order{
				&__image{
					width: 30%;
					padding-right: 15px;
					margin-top: 0;
				}
				&__name{
					width: 70%;
					margin-top: 0;
				}
				&__size{
					width: 35%;
					margin-top: @spacing-small;
				}
				&__quantity{
					width: 35%;
					text-align: right;
					margin-top: @spacing-small;
				}
				&__total{
					float: left;
					margin-top: @spacing-small;
				}
			}
		}
		& &__address,
		& &__card{
			&__header{
				font-size: 13px;
				padding: @spacing-small;
				font-weight: 400;
			}
		}
	}
}