.tooltip {
  float: left;
  position: relative;
}
.tooltip__content {
  display: none;
  position: absolute;
  bottom: 22px;
  padding: 5px;
  background: #000;
  color: #fff;
  border-radius: 2px;
  width: 100px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 11px;
  text-align: left;
}
.tooltip__content:after,
.tooltip__content:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.tooltip__content:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
}
.tooltip__content:before {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  border-top-color: #000;
}
.tooltip__content--top:after,
.tooltip__content--top:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.tooltip__content--top:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000;
}
.tooltip__content--top:before {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid;
  border-bottom-color: #000;
}
.tooltip__content__header {
  font-weight: 500;
  padding: 5px;
  margin: -5px -5px -1px;
  float: left;
  width: 100%;
  box-sizing: content-box;
  border-bottom: 1px solid #222;
}
.tooltip__content__row {
  display: block;
  border-top: 1px solid #222;
  padding: 5px;
  text-align: center;
  float: left;
  width: 100%;
}
.tooltip__content--center {
  text-align: center;
}
.tooltip:hover:before {
  content: '';
  position: absolute;
  top: -8px;
  width: 200%;
  height: 18px;
  left: -50%;
}
.tooltip:hover .tooltip__content {
  display: block;
}
.tooltip--links {
  padding: 0;
}
.tooltip--links .tooltip__link {
  padding: 10px;
  float: left;
  width: 100%;
  border-top: 1px solid #222;
  cursor: pointer;
  line-height: 1.5;
  text-decoration: none;
  color: #fff;
}
.tooltip--links .tooltip__link i {
  margin-right: 5px;
}
.tooltip--links .tooltip__link:first-child {
  border-top: 0;
}
.tooltip--links .tooltip__link:first-child:hover {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.tooltip--links .tooltip__link:last-child:hover {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.tooltip--links .tooltip__link:hover {
  background: #222;
}
.tooltip--links button {
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-align: left;
}
