.checkout-totals:after {
  content: '';
  display: block;
  clear: both;
}
.checkout-totals__container {
  background: #fff;
  border: 1px solid #eee;
}
.checkout-totals__header {
  padding: 15px;
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
}
.checkout-totals__content {
  padding: 15px;
  border-bottom: 1px solid #eee;
}
.checkout-totals__row {
  padding: 15px;
  border-top: 1px solid #eee;
}
.checkout-totals__row:after {
  content: '';
  display: block;
  clear: both;
}
.checkout-totals__row:first-child {
  margin-top: 0;
  border-top: 0;
}
.checkout-totals__row span {
  float: right;
}
.checkout-totals__row span.select {
  width: 100%;
  margin-top: 5px;
}
.checkout-totals__row span select {
  width: 100%;
}
.checkout-totals__row--total span {
  font-weight: 500;
}
.checkout-totals__row--total a {
  float: right;
  margin-right: 10px;
  color: #e74c3c;
}
.checkout-totals__button {
  margin-top: 15px;
}
.checkout-totals__button a {
  width: 100%;
}
.checkout-totals--hidden {
  opacity: 0.4;
  cursor: not-allowed;
}
.checkout-totals--hidden * {
  pointer-events: none;
}
.checkout-totals .checkout-totals__item {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #eee;
  position: relative;
}
.checkout-totals .checkout-totals__item:after {
  content: '';
  display: block;
  clear: both;
}
.checkout-totals .checkout-totals__item:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}
.checkout-totals .checkout-totals__item__image {
  float: left;
  width: 20%;
  padding-right: 3%;
  position: relative;
}
.checkout-totals .checkout-totals__item__content {
  width: 80%;
  float: left;
}
.checkout-totals .checkout-totals__item__name {
  float: 70%;
  float: left;
}
.checkout-totals .checkout-totals__item__price {
  float: right;
  width: 30%;
  text-align: right;
}
.checkout-totals .checkout-totals__item__size {
  clear: both;
  float: left;
  margin-top: 5px;
}
.checkout-totals .checkout-totals__item__quantity {
  float: left;
  padding-left: 15px;
  margin-top: 5px;
}
.checkout-totals .checkout-totals__item__qty {
  float: left;
}
.checkout-totals__message {
  padding: 10px;
  margin-top: 15px;
}
.checkout-totals__message--info {
  background: #3498db;
  color: #fff;
  text-align: center;
}
.checkout-totals__message--info:before {
  content: '\f05a';
  margin-right: 10px;
  font-family: 'FontAwesome';
}
.checkout-totals__links {
  margin-top: 15px;
}
.checkout-totals__links a {
  display: block;
  margin-top: 5px;
}
.checkout-totals__links a:first-child {
  margin-top: 0;
}
