@import '../_functions.less';
@import '../_variables.less';
.featured {
	&__image{
		margin-bottom: @spacing-small;
	}
	a{
		text-decoration: none;
		color: inherit;
	}
}