.display-box {
  text-decoration: none;
  color: inherit;
}
.display-box .display-box__content {
  text-align: center;
}
.display-box .display-box__content h4 {
  margin-bottom: 15px;
}
.display-box .display-box__content p {
  margin: 0;
}
.display-box .display-box__content a {
  color: inherit;
}
.display-box .display-box__icon {
  border: 3px solid;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin: 0 auto 15px;
  cursor: pointer;
}
.display-box .display-box__icon * {
  color: inherit;
}
.display-box .display-box__icon i {
  font-size: 30px;
  margin-top: 30px;
  line-height: 1.1;
}
.display-box:hover .display-box__icon {
  background: #000;
  color: #fff;
}
