@import '../_functions.less';
@import '../_variables.less';
.product{
    &__description{
        margin-bottom: @spacing-base;
        p:last-child{
            margin-bottom: 0;
        }
        ul{
            margin-bottom: 0;
        }
    }
    //gallery block
    &__gallery{
        position: relative;
        &__main-image{
            border-bottom: 1px solid @color-border;
            position: relative;
            &__right-arrow{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                padding: @spacing-base;
                background: #000;
                color: #fff;
                text-align: center;
                line-height: 1;
                cursor: pointer;
            }
            &__left-arrow{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                padding: @spacing-base;
                background: #000;
                color: #fff;
                text-align: center;
                line-height: 1;
                cursor: pointer;
            }
            &__number-display{
                position: absolute;
                top: @spacing-small;
                right: @spacing-small;
                padding: @spacing-small;
                background: #000;
                color: #fff;
                font-weight: 500;
                font-size: @font-size-small;
            }
        }
        &__thumbs{
            margin: 0 -@spacing-xs;
            .clearfix();
        }
        &__thumb{
            float: left;
            width: 20%;
            cursor: pointer;
            padding: @spacing-small @spacing-xs;
            opacity: 0.4;
            img{
                pointer-events: none;
            }
            &--selected{
                opacity: 1;
                position: relative;
                .arrow(8px, #fff, top, 50%, 1px, @color-border);
            }
        }
    }
    &__variations{
        padding-bottom: @spacing-large;
        .clearfix();
        //child blocks
        .button{
            width: 100%;
            height: auto;
            line-height: 1.5;
            font-weight: 500;
            min-width: 0;
            background: @color-success;
            border: 1px solid @color-success-dark;
        }
    }
}