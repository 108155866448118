@import '../_functions.less';
@import '../_variables.less';
.tabbed{
	//border: 1px solid @color-base;
	.clearfix();
	&__tabs{
		&--2{
			.tabbed__tab{
				width: 50%;
			}
		}
		.clearfix();
	}
	&__tab{
		width: 33.333%;
		float: left;
		padding: @spacing-small;
		text-align: center;
		//opacity: 0.8;
		cursor: pointer;
		border-bottom: 2px solid @color-border;
		//elements
		i{
			margin-right: @spacing-base;
		}
		//modifiers
		&--active{
			opacity: 1;
			border-bottom: 2px solid @color-base;
		}
	}
	&__content{
		padding: @spacing-base;
		display: none;
		.clearfix();
		p{
			line-height: 1.8;
			margin-top: 0;
			margin-bottom: @spacing-xs;
		}
		//modifiers
		&--active{
			display: block;
		}
	}
}
//modifier
.tabbed--vertical{
	.tabbed{
		&__tabs{
			float: left;
			width: 30%;
			border-right: 1px solid @color-border;
		}
		&__contents{
			float: left;
			width: 70%;
			border-left: 1px solid @color-border;
			margin-left: -1px;
		}
		&__tab{
			width: 100%;
			border-bottom: 0;
			border-top: 1px solid @color-border;
			text-align: left;
			padding: @spacing-large;
			&:hover{
				background: @color-grey;
			}
			&:first-child{
				border-top: 0;
			}
			i{
				margin-right: @spacing-small;
			}
			//modifiers
			&--active{
				background: #000;
				color: #fff;
				border-color: #000;
				font-weight: 500;
				&:hover{
					background: #000;
				}
			}
		}
	}
}
//responsive overrides
@media screen and (max-width: @screen-size-medium){
	.tabbed--vertical{
		.tabbed{
			&__tabs{
				width: 100%;
				border-right: 0;
			}
			&__contents{
				width: 100%;
				border-left: 0;
			}
			&__tab{
				padding: @spacing-base;
			}
		}
	}
}