.slider {
  background: #f9f9f9;
  width: 100%;
  height: 20px;
  border-radius: 30px;
  border: 1px solid #e0e0e0;
  position: relative;
}
.slider__handle {
  border: 10px solid #20638f;
  background: #fff;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -6px;
  left: -1px;
  border-radius: 30px;
  z-index: 10;
  cursor: -webkit-grab;
}
.slider__progress {
  background: #3498db;
  position: absolute;
  left: -1px;
  top: -1px;
  height: 20px;
  width: 30px;
  pointer-events: none;
  z-index: 0;
  border-radius: 30px;
  border: 1px solid #2980b9;
}
.slider__output {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}
.slider--space {
  margin: 25px 0 15px;
}
