.grid {
  margin: 3% -1.5%;
}
.grid:after {
  content: '';
  display: block;
  clear: both;
}
.grid__item {
  float: left;
  padding: 0 1.5%;
}
.grid__item--right {
  float: right;
}
.grid__item--1 {
  width: 1%;
}
.grid__item--2 {
  width: 16.6%;
}
.grid__item--3 {
  width: 25%;
}
.grid__item--3:nth-child(4n+1) {
  clear: left;
}
.grid__item--3:nth-child(1n+5) {
  margin-top: 15px;
}
.grid__item--4 {
  width: 33.33%;
}
.grid__item--4:nth-child(3n+1) {
  clear: left;
}
.grid__item--4:nth-child(1n+4) {
  margin-top: 15px;
}
.grid__item--5 {
  width: 41.6%;
}
.grid__item--6 {
  width: 50%;
}
.grid__item--6:nth-child(2n+1) {
  clear: left;
}
.grid__item--6:nth-child(1n+3) {
  margin-top: 15px;
}
.grid__item--7 {
  width: 58.4%;
}
.grid__item--8 {
  width: 66.6%;
}
.grid__item--9 {
  width: 75.1%;
}
.grid__item--10 {
  width: 83.3%;
}
.grid__item--11 {
  width: 91.7%;
}
.grid__item--12 {
  width: 100%;
}
.grid--no-margin {
  margin-top: 0;
  margin-bottom: 0;
}
.grid--no-gutter {
  margin: 3% 0;
}
.grid--no-gutter .grid__item {
  padding: 0;
}
@media screen and (max-width: 720px) {
  .grid__item {
    width: 100%;
    margin-top: 15px;
  }
  .grid__item:first-child {
    margin-top: 0;
  }
  .grid--two-column .grid__item {
    width: 50%;
    margin-top: 0;
  }
}
