.product__description {
  margin-bottom: 15px;
}
.product__description p:last-child {
  margin-bottom: 0;
}
.product__description ul {
  margin-bottom: 0;
}
.product__gallery {
  position: relative;
}
.product__gallery__main-image {
  border-bottom: 1px solid #eee;
  position: relative;
}
.product__gallery__main-image__right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding: 15px;
  background: #000;
  color: #fff;
  text-align: center;
  line-height: 1;
  cursor: pointer;
}
.product__gallery__main-image__left-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  padding: 15px;
  background: #000;
  color: #fff;
  text-align: center;
  line-height: 1;
  cursor: pointer;
}
.product__gallery__main-image__number-display {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background: #000;
  color: #fff;
  font-weight: 500;
  font-size: 11px;
}
.product__gallery__thumbs {
  margin: 0 -5px;
}
.product__gallery__thumbs:after {
  content: '';
  display: block;
  clear: both;
}
.product__gallery__thumb {
  float: left;
  width: 20%;
  cursor: pointer;
  padding: 10px 5px;
  opacity: 0.4;
}
.product__gallery__thumb img {
  pointer-events: none;
}
.product__gallery__thumb--selected {
  opacity: 1;
  position: relative;
}
.product__gallery__thumb--selected:after,
.product__gallery__thumb--selected:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.product__gallery__thumb--selected:after {
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
}
.product__gallery__thumb--selected:before {
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid;
  border-bottom-color: #eee;
}
.product__variations {
  padding-bottom: 30px;
}
.product__variations:after {
  content: '';
  display: block;
  clear: both;
}
.product__variations .button {
  width: 100%;
  height: auto;
  line-height: 1.5;
  font-weight: 500;
  min-width: 0;
  background: #27ae60;
  border: 1px solid #1e8449;
}
