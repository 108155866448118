@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader--active:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #ffffff;
  z-index: 800;
}
.loader--active .loader__circle {
  margin: 15px auto;
  font-size: 10px;
  position: absolute;
  text-indent: -9999em;
  border-top: 0.3em solid #e1f0fa;
  border-right: 0.3em solid #e1f0fa;
  border-bottom: 0.3em solid #e1f0fa;
  border-left: 0.3em solid #2980b9;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  -webkit-animation: loader 1.1s infinite linear;
  animation: loader 1.1s infinite linear;
  margin: 0 auto;
  z-index: 801;
}
.loader--active .loader__circle:after,
.loader--active .loader__circle {
  border-radius: 50%;
  width: 4em;
  height: 4em;
}
.loader--active .loader__circle.tiny:after,
.loader--active .loader__circle.tiny {
  border-radius: 50%;
  width: 4em;
  height: 4em;
}
.loader--boxed:before {
  position: absolute;
  z-index: 1;
}
.loader--boxed .loader__circle {
  z-index: 2;
}
