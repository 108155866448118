/** Payment Success **/
.payment-success {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}
.payment-success h4 {
  margin-bottom: 15px;
}
.payment-success__reference {
  padding: 15px;
  background: #27ae60;
  margin-top: -1px;
  color: #fff;
}
.payment-success__reference:after {
  content: '';
  display: block;
  clear: both;
}
.payment-success__reference span {
  text-transform: uppercase;
  float: right;
}
@media screen and (max-width: 450px) {
  .payment-success__reference span {
    font-size: 13px;
    float: left;
    margin-top: 5px;
  }
}
.payment-success__header {
  background: #1e8449;
  color: #fff;
}
.payment-success__row {
  border-top: 1px solid #eee;
  margin-top: 15px;
}
.payment-success__row:first-child {
  border-top: 0;
  margin-top: 0;
}
.payment-success__row b {
  display: block;
  margin-bottom: 10px;
}
.payment-success__row--totals {
  background: #fafafa;
  padding: 15px;
  margin: 15px -15px -15px;
}
.payment-success__row--totals h4 {
  margin-bottom: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}
.payment-success__total {
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.payment-success__total span {
  float: right;
  font-weight: 500;
}
.payment-success__total span i {
  margin-right: 5px;
}
.payment-success__total--last {
  padding: 15px 0 0;
  border: 0;
}
.payment-success--center {
  text-align: center;
}
.payment-success .address {
  padding: 0;
  border: 0;
}
