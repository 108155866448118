@import '../_functions.less';
@import '../_variables.less';
.form{
	h4{
		margin-bottom: @spacing-small;
		margin-top: @spacing-base;
		padding-top: @spacing-base;
		border-top: 1px solid @color-border;
	}
	& &__row{
		.clearfix();
		margin-top: @spacing-base;
		&:first-child{
			margin-top: 0;
		}
		b{
			margin-bottom: @spacing-xs;
			display: block;
		}
		input,
		select{
			padding: @spacing-small;
			font-weight: 300;
		}
		label{
			font-weight: 300;
		}
		&[data-required]{
			b:after{
				content: ' *';
				color: @color-danger;
				margin-left: 2px;
			}
		}
		//modifiers - __row block
		&--error{
			input{
				border-color: @color-danger;
			}
		}
		&--no-margin{
			margin-top: 0;
		}
	}
	& &__link{
		float: right;
	}
	& &__message{
		background: @color-danger;
		color: #fff;
		margin-top: @spacing-small;
		padding: @spacing-small;
		border: 1px solid @color-danger-dark;
		font-size: @font-size-small;
		float: left;
		position: relative;
		.arrow(@spacing-xs, @color-danger, top, 15px, 0.5px, @color-danger-dark);
		//modifiers
		&--full-width{
			float: none;
			width: 100%;
			&:after,
			&:before{
				content: '';
				height: 0;
				width: 0;
				position: initial;
				background: transparent;
				border: 0;
				margin: 0;
			}
		}
	}
	//modifiers - main block
	&--card{
		input.auto{
			width: auto;
		}
	}
	[data-stripe='cvc']{
		float: left;
		+ .form__message{
			clear: both;
		}
	}
	//child blocks
	.toggle--active{
		& + .form--billing{
			display: none;
		}
	}
}