@import '../_functions.less';
@import '../_variables.less';
.checkout-totals{
	.clearfix();
	&__container{
		background: #fff;
		border: 1px solid @color-border;
	}
	&__header{
		padding: @spacing-base;
		border-bottom: 1px solid @color-border;
		margin-bottom: 0;
	}
	&__content{
		padding: @spacing-base;
		border-bottom: 1px solid @color-border;
	}
	&__row{
		padding: @spacing-base;
		border-top: 1px solid @color-border;
		.clearfix();
		&:first-child{
			margin-top: 0;
			border-top: 0;
		}
		span{
			float: right;
			&.select{
				width: 100%;
				margin-top: @spacing-xs;
			}
			select{
				width: 100%;
			}
		}
		//modifier
		&--total{
			span{
				font-weight: 500;
			}
			a{
				float: right;
				margin-right: 10px;
				color: @color-danger;
			}
		}
	}
	&__button{
		margin-top: @spacing-base;
		a{
			width: 100%;
		}
	}
	&--hidden{
		opacity: 0.4;
		cursor: not-allowed;
		*{
			pointer-events: none;
		}
	}
    & &__item{
        padding-top: @spacing-base;
        margin-top: @spacing-base;
        border-top: 1px solid @color-border;
        position: relative;
        .clearfix();
        &:first-child{
            border-top: 0;
            padding-top: 0;
            margin-top: 0;
        }
        &__image{
            float: left;
            width: 20%;
            padding-right: 3%;
            position: relative;
        }
        &__content{
            width: 80%;
            float: left;
        }
        &__name{
            float: 70%;
            float: left;
        }
        &__price{
            float: right;
            width: 30%;
            text-align: right;
        }
        &__size{
            clear: both;
            float: left;
            margin-top: @spacing-xs;
        }
        &__quantity{
            float: left;
            padding-left: @spacing-base;
            margin-top: @spacing-xs;
        }
        &__qty{
            float: left;
        }
    }
    &__message{
    	padding: @spacing-small;
    	margin-top: @spacing-base;
    	// modifiers
    	&--info{
    		background: @color-info;
    		color: #fff;
    		text-align: center;
    		&:before{
    			content: '\f05a';
    			margin-right: @spacing-small;
    			font-family: 'FontAwesome';
    		}
    	}
    }
    &__links{
        margin-top: @spacing-base;
        a{
            display: block;
            margin-top: @spacing-xs;
            &:first-child{
                margin-top: 0;
            }
        }
    }
}