@import '../_functions.less';
@import '../_variables.less';
@import '../_animations.less';
.side-cart{
    position: fixed;
    width: 375px;
    max-width: 90%;
    top: 0;
    background: #fff;
    height: 100%;
    z-index: 102;
    right: 0;
    transform: translate3d(100%, 0, 0);
    transition: transform .25s ease;
    z-index: 201;
    &__header{
    	margin: 0;
    	background: #000;
    	color: #fff;
    	font-weight: 300;
    	padding: @spacing-base;
    }
    &__content{
    	padding: @spacing-base;
        position: relative;
        .clearfix();
    }
    &__close{
    	float: right;
    	cursor: pointer;
    }
    &__open{
        position: relative;
    }
    &__quantity{
        position: absolute;
        font-size: 7px;
        padding: 2px 4.5px;
        background: @color-danger;
        border: 1px solid @color-danger-dark;
        color: #fff;
        border-radius: 30px;
        bottom: 7px;
        left: 22px;
    }
    &__items{
        overflow: auto;
        border: 0;
        margin-top: @spacing-base;
        position: fixed;
        bottom: 107px;
        background-color: #fff;
        top: 36px;
        z-index: 1;
        left: 0;
        width: 100%;
    }
    & &__item{
        padding: @spacing-base;
        border-top: 1px solid @color-border;
        position: relative;
        width: 100%;
        float: left;
        .clearfix();
        &:first-child{
            border-top: 0;
        }
        &__image{
            float: left;
            width: 30%;
            padding-right: 3%;
            position: relative;
        }
        &__remove{
            background: @color-danger;
            position: absolute;
            top: 0px;
            left: 0;
            color: #fff;
            line-height: 0;
            padding: 5px 5px;
            border-radius: 0px;
            font-size: 11px;
            cursor: pointer;
        }
        &__content{
            width: 70%;
            float: left;
        }
        &__name{
            float: 70%;
            float: left;
        }
        &__price{
            float: right;
            width: 30%;
            text-align: right;
        }
        &__size{
            clear: both;
            margin-top: @spacing-small;
        }
        &__quantity{
            float: left;
            line-height: 1.5;
            margin-top: @spacing-small;
            .clearfix();
        }
        &__decrement,
        &__increment{
            float: left;
            color: @color-info-dark;
            border: 1px solid @color-info-dark;
            padding: 4px 7px;
            font-size: 8px;
            border-radius: 18px;
            cursor: pointer;
            &:hover{
                background: @color-info-light;
            }
        }
        &__decrement[data-disabled="true"]{
            border-color: @color-grey-dark;
            color: @color-grey-dark;
            pointer-events: none;
            cursor: not-allowed;
        }
        &__qty{
            float: left;
            padding: 0 @spacing-small;
        }
    }
    &__loader-container{
        //modifiers
        &--active{
            &:before{
                content: '';
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: rgba(255, 255, 255, 0.8);
                z-index: 2;
            }
            .side-cart{
                &__loader{
                    .loader(@color-info-light, @color-info-dark);
                    z-index: 2;
                }
            }
        }
        &__loader{
        }
    }
    &__totals{
        position: absolute;
        bottom: 0;
        padding: @spacing-base;
        width: 100%;
        border-top: 1px solid @color-border;
        background: #f9f9f9;
        z-index: 1;
    }
    &__total-row{
        margin-bottom: @spacing-base;
        .clearfix();
        b{
            float: right;
        }
    }
    &__buttons{
        .clearfix();
        a{
            float: left;
            width: 100%;
            min-width: initial;
        }
    }
}
//modifier blocks
.side-cart--active{
	//using for structer
	&.side-cart{
		transform: translate3d(0, 0, 0);
	}
}
.side-cart--hidden{
    .side-cart__totals{
        display: none;
    }
}