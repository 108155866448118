.breadcrumbs {
  text-align: center;
}
.breadcrumbs:after {
  content: '';
  display: block;
  clear: both;
}
.breadcrumbs .breadcrumbs__item {
  text-decoration: none;
  width: 100%;
  float: left;
  padding: 15px;
  background: #fff;
  opacity: 0.5;
  border: 1px solid #e0e0e0;
  pointer-events: none;
  color: inherit;
  border-top: 0;
}
.breadcrumbs .breadcrumbs__item[data-selectable] {
  opacity: 1;
  pointer-events: initial;
}
.breadcrumbs .breadcrumbs__item[data-selectable]:hover {
  background: #e1f0fa;
  color: #2980b9;
  border-color: #2980b9;
}
.breadcrumbs .breadcrumbs__item[data-selected] {
  opacity: 1;
  background: #2980b9;
  color: #fff;
  border-color: #2980b9;
  pointer-events: none;
}
.breadcrumbs .breadcrumbs__item[data-selected]:hover {
  background: #2980b9;
  color: #fff;
}
.breadcrumbs--progress {
  display: none;
}
@media screen and (max-width: 720px) {
  .breadcrumbs--progress {
    display: block;
    background: #fff;
    border: 1px solid #1e8449;
    margin-top: 15px;
  }
  .breadcrumbs__progress-bar {
    background: #1e8449;
    padding: 15px;
    color: #fff;
  }
  .breadcrumbs__progress-bar[data-progress="0"] {
    width: 0%;
    padding: 15px 0;
  }
  .breadcrumbs__progress-bar[data-progress="0"]:before {
    content: 'Verify your cart';
  }
  .breadcrumbs__progress-bar[data-progress="1"] {
    width: 25%;
    color: #1e8449;
  }
  .breadcrumbs__progress-bar[data-progress="1"]:before {
    content: '25% - Fill out your details';
  }
  .breadcrumbs__progress-bar[data-progress="2"] {
    width: 50%;
  }
  .breadcrumbs__progress-bar[data-progress="2"]:before {
    content: '50% - Select shipping method';
  }
  .breadcrumbs__progress-bar[data-progress="3"] {
    width: 75%;
  }
  .breadcrumbs__progress-bar[data-progress="3"]:before {
    content: 'Now pay!';
  }
  .breadcrumbs .grid {
    display: none;
  }
}
