@import '../_functions.less';
@import '../_variables.less';
.display-box{
	text-decoration: none;
	color: inherit;
	& &__content{
		text-align: center;
		h4{
			margin-bottom: @spacing-base;
		}
		p{
			margin: 0;
		}
		a{
			color: inherit;
		}
	}
	& &__icon{
		border: 3px solid;
		width: 100px;
		height: 100px;
		border-radius: 50px;
		margin: 0 auto @spacing-base;
		cursor: pointer;
		*{
			color: inherit;
		}
		i{
			font-size: 30px;
			margin-top: @spacing-large;
			line-height: 1.1;
		}
	}
	//psuedo elements
	&:hover{
		//blockifying
		.display-box{
			&__icon{
				// color: @color-success-dark;
				background: #000;
				color: #fff;
			}
		}
	}
}