@import '../_functions.less';
@import '../_variables.less';
@import '../_animations.less';

.loader{
    &--active{
        &:before{
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba(255, 255, 255, 1);
            z-index: 800;
        }
        .loader{
            &__circle{
                .loader(@color-info-light, @color-info-dark);
                margin: 0 auto;
                z-index: 801;
            }
        }
    }
    &--boxed{
        &:before{
            position: absolute;
            z-index: 1;
        }
        .loader__circle{
            z-index: 2;
        }
    }
}