@import '../_functions.less';
@import '../_variables.less';
.toggle{
	width: 60px;
	margin: @spacing-small 0;
	input{
		display: none;
	}
	label{
		cursor: pointer;
	}
	&__content{
		background: @color-danger;
		border: 1px solid @color-danger-dark;
		border-radius: 30px;
		transition: all .25s ease;
	}
	&__button{
		height: 22px;
		width: 22px;
		transition: all .25s ease;
		border-radius: 30px;
		background: @color-grey;
		border: 0.5px solid @color-border;
		position: relative;
		left: 0;
	}
	input:checked{
		+ .toggle{
			&__content{
				background: @color-success;
				border-color: @color-success-dark;
				.toggle__button{
					margin-left: 100%;
					left: -22px;
				}
			}
		}
	}
	//modifier
	&--mini{
		margin: 0;
		width: 45px;
		.toggle{
			&__button{
				height: 16px;
				width: 16px
			}
		}
		input:checked{
			+ .toggle{
				&__content{
					background: @color-success;
					border-color: @color-success-dark;
					.toggle__button{
						margin-left: 100%;
						left: -16px;
					}
				}
			}
		}
	}
}