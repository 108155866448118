.card__row {
  margin-top: 5px;
}
.card__row:first-child {
  margin-top: 0;
}
.card__image {
  position: relative;
}
.card__details {
  padding: 10px;
  border: 1px solid #eee;
  border-top: 0;
  background: #f9f9f9;
}
.card__name {
  font-weight: 500;
}
.card__price {
  float: right;
}
.card__label {
  position: absolute;
  bottom: 15px;
  right: 0;
  padding: 10px;
  color: #fff;
  border-right: 0;
  text-decoration: none;
  font-size: 11px;
  transition: all .25s ease;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.card__label i {
  margin-right: 5px;
}
.card__label--warning {
  background: #e74c3c;
}
.card__buttons {
  border: 1px solid #eee;
  border-top: 0;
}
.card__buttons:after {
  content: '';
  display: block;
  clear: both;
}
.card__buttons a {
  padding: 10px 15px;
  float: left;
}
.card__buttons a:hover {
  background: #f9f9f9;
}
.card__buttons__right {
  float: right;
}
.card__buttons__right a {
  border-left: 1px solid #eee;
}
.card__buttons__left {
  float: left;
}
.card__buttons__left a {
  border-right: 1px solid #eee;
}
.card__button {
  color: #20638f;
}
.card__button--danger {
  color: #e74c3c;
}
