//clearfix
.clearfix(){
	&:after{
		content: '';
		display: block;
		clear: both;
	}
}

//arrow
.arrow(@size, @color, @direction, @offset, @border-size: 0, @border-color: inherit){
	&:after,
	&:before {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
	}
}

.arrow(@size, @color, @direction, @offset, @border-size: 0, @border-color: inherit) when (@direction = top){
	@m-size: @size + (@border-size*2);

	&:after {
		bottom: 100%;
		left: @offset;
		margin-left: -@size;
		border-left: @size solid transparent;
		border-right: @size solid transparent;
		border-bottom: @size solid @color;
	}
	&:before {
		bottom: 100%;
		left: @offset;
		margin-left: -@m-size;
		border-left: @m-size solid transparent;
		border-right: @m-size solid transparent;
		border-bottom: @m-size solid;
		border-bottom-color: @border-color;
	}
}

.arrow(@size, @color, @direction, @offset, @border-size: 0, @border-color: inherit) when (@direction = bottom){
	@m-size: @size + (@border-size*2);

	&:after {
		top: 100%;
		left: @offset;
		margin-left: -@size;
		border-left: @size solid transparent;
		border-right: @size solid transparent;
		border-top: @size solid @color;
	}

	&:before {
		top: 100%;
		left: @offset;
		margin-left: -@m-size;
		border-left: @m-size solid transparent;
		border-right: @m-size solid transparent;
		border-top: @m-size solid;
		border-top-color: @border-color;
	}
}

.arrow(@size, @color, @direction, @offset, @border-size: 0, @border-color: inherit) when (@direction = right){
	@m-size: @size + (@border-size*2);

	&:after {
		left: 100%;
		top: @offset;
		margin-top: -@size;
		border-top: @size solid transparent;
		border-bottom: @size solid transparent;
		border-left: @size solid @color;
	}
	&:before {
		left: 100%;
		top: @offset;
		margin-top: -@m-size;
		border-top: @m-size solid transparent;
		border-bottom: @m-size solid transparent;
		border-left: @m-size solid;
		border-left-color: @border-color;
	}
}

.arrow(@size, @color, @direction, @offset, @border-size: 0, @border-color: inherit) when (@direction = left){
	@m-size: @size + (@border-size*2);

	&:after {
		right: 100%;
		top: @offset;
		margin-top: -@size;
		border-top: @size solid transparent;
		border-bottom: @size solid transparent;
		border-right: @size solid @color;
	}
	&:before {
		right: 100%;
		top: @offset;
		margin-top: -@m-size;
		border-top: @m-size solid transparent;
		border-bottom: @m-size solid transparent;
		border-right: @m-size solid;
		border-right-color: @border-color;
	}
}

.positionElem(@x, @y, @top, @left){
	position: absolute;
	transform: translate3d(@x, @y, 0);
	top: @top;
	left: @left;
}