@import '../_functions.less';
@import '../_variables.less';
.footer{
	padding: @spacing-xl 0;
	font-size: @font-size-small;
	&__links{
		margin: 0 auto;
		display: block;
		text-align: center;
		a{
			text-decoration: none;
			color: @color-base;
			&:before{
				content: '|';
				padding-left: @spacing-base;
				margin-right: @spacing-base;
			}
			&:first-child{
				&:before{
					content: '';
					padding: 0;
					margin: 0;
				}
			}
		}
	}
	&__social{
		text-align: center;
		margin-top: @spacing-base;
		margin: @spacing-large auto 0;
		width: 95px;
		.clearfix();
		a{
			margin-left: @spacing-base;
			float: left;
			i{
				border: 1px solid @color-base;
				padding: 14px 9px 10px 10px;
				width: 40px;
				height: 40px;
				border-radius: 30px;
				color: @color-base;
				&:hover{
					background: @color-base;
					color: #fff;
				}
			}
			&:first-child{
				margin-left: 0;
			}
		}
	}
}