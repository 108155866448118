/** styles/components/_form-selector.less **/
.form-selector {
  border: 1px solid #eee;
}
.form-selector .form-selector__item {
  display: block;
  width: 100%;
  padding: 15px;
  border-top: 1px solid #eee;
  cursor: pointer;
}
.form-selector .form-selector__item:after {
  content: '';
  display: block;
  clear: both;
}
.form-selector .form-selector__item:first-child {
  border-top: 0;
}
.form-selector .form-selector__item:hover {
  background: #fafafa;
}
.form-selector .form-selector__item span {
  float: right;
}
