.table .table__row:after {
  content: '';
  display: block;
  clear: both;
}
.table .table__row--header {
  font-weight: 500;
}
.table .table__cell {
  float: left;
  padding: 10px;
  text-align: center;
}
.table .table__cell b {
  margin: 0;
}
.table .table__cell--empty {
  opacity: 0;
}
.table__header {
  background: #fafafa;
  padding: 15px;
  text-transform: uppercase;
}
.table__header i {
  margin-right: 10px;
}
.table__footer {
  background: #fafafa;
  padding: 15px;
  border-top: 1px solid #eee;
  float: left;
  width: 100%;
}
.table__footer:after {
  content: '';
  display: block;
  clear: both;
}
.table__footer b {
  margin-right: 10px;
}
.table__footer i {
  margin-right: 5px;
}
.table--column .table__cell {
  width: 25%;
}
.table--border {
  border: 1px solid #eee;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
}
.table--border:after {
  content: '';
  display: block;
  clear: both;
}
.table--border .table__cell {
  padding: 15px;
}
.table--border .table__cell--img {
  width: 1%;
}
.table--border .table__cell--img img {
  width: 30px;
}
.table--border .table__row {
  float: left;
  width: 100%;
  background: #fff;
  border-top: 1px solid #eee;
}
.table--border .table__row:after {
  content: '';
  display: block;
  clear: both;
}
.table--border .table__row:first-child {
  border-top: 0;
}
.table--margin {
  margin-top: 15px;
}
.table--margin:first-child {
  margin-top: 0;
}
.table--4-column .table__cell {
  width: 25%;
}
.table__tag {
  padding: 15px;
  max-width: 65px;
  border-radius: 3px;
}
.table__actions {
  text-align: right;
}
table {
  border: 1px solid #eee;
  border-collapse: collapse;
  width: 100%;
}
table th {
  padding: 15px;
  background: #f9f9f9;
  font-weight: 500;
  text-align: left;
}
table th.table__actions {
  text-align: right;
}
table th.table__centered {
  text-align: center;
}
table td {
  padding: 15px;
}
table td.table__centered {
  text-align: center;
}
table tr {
  border-top: 1px solid #eee;
}
table .table__success {
  color: #27ae60;
}
table .table__error {
  color: #e74c3c;
}
table .tooltip {
  float: none;
}
.table-new {
  border: 1px solid #eee;
}
.table-new .table-new__headers {
  background: #f9f9f9;
  border-bottom: 1px solid #eee;
  font-weight: 500;
}
.table-new .table-new__headers:after {
  content: '';
  display: block;
  clear: both;
}
.table-new .table-new__header,
.table-new .table-new__cell {
  padding: 15px;
  float: left;
}
.table-new .table-new__header--no-results,
.table-new .table-new__cell--no-results {
  text-align: center;
}
.table-new .table-new__header--12,
.table-new .table-new__cell--12 {
  width: 100%;
}
.table-new .table-new__header--6,
.table-new .table-new__cell--6 {
  width: 50%;
}
.table-new .table-new__header--3,
.table-new .table-new__cell--3 {
  width: 25%;
}
.table-new .table-new__header--4,
.table-new .table-new__cell--4 {
  width: 33.33%;
}
.table-new .table-new__row {
  border-top: 1px solid #eee;
}
.table-new .table-new__row:after {
  content: '';
  display: block;
  clear: both;
}
.table-new .table-new__row:first-child {
  border-top: 0;
}
