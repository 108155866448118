@import '../_functions.less';
@import '../_variables.less';
.instagram-feed{
	position: relative;
	min-height: 180px;
	background: #999;
	.clearfix();
	&:hover{
		.instagram-feed__overlay{
			background: rgba(0, 0, 0, 0.4);
		}
	}
	&__logo{
		position: absolute;
		font-size: 40px;
		color: #fff;
		z-index: 1;
		line-height: 1;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		text-align: center;
		i{
			padding: @spacing-base 18px;
			border: 5px solid;
			border-radius: 50px;
		}
		span{
			display: block;
			font-size: @font-size-large;
			margin-top: @spacing-small;
			font-weight: 300;
		}
	}
	a{
		display: block;
	}
	&__images{
		a{
			float: left;
			width: 20%;
			line-height: 0;
			position: relative;
			overflow: hidden;
			@media screen and (max-width: 720px) {
				width: 33.33%;
			}
			img{
				width: auto;
				height: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);
			}
		}
	}
	&__overlay{
		position: absolute;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.8);
		transition: all .25s ease;
	}
	&__show-more{
		position: absolute;
		bottom: @spacing-base;
		right: @spacing-base;
		font-weight: 500;
		font-size: @font-size-small;
	}
}