@import '_functions.less';
@import '_variables.less';

html, body{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat';
    background: #fff;
    font-size: @font-size-base;
    color: #222;
    font-weight: 300;
    *{
        box-sizing: border-box;
    }
    &.checkout{
        background: #f9f9f9;
    }
}
body{
    &.no-scroll{
        overflow-y: hidden;
    }
    &.blog{
        background: #f9f9f9;
    }
}
h1,
h2,
h3,
h4{
    text-transform: uppercase;
    margin-top: 0;
}
form{
    margin: 0;
}
ul{
    font-weight: 300;
    padding-left: @spacing-base;
    margin: @spacing-base 0 @spacing-base @spacing-base;
}
select,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"]{
    width: 100%;
    padding: @spacing-base;
    -webkit-appearance: none;
    border-radius: 0;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    font-family: 'Montserrat';
    border: 1px solid rgb(166, 166, 166);
}
input[type="date"]{
    padding: 8.5px!important;
}
input[type="text"][size]{
    width: auto;
}
input[type="password"]{
    letter-spacing: @spacing-xs;
}
textarea{
    width: 100%;
    padding: @spacing-base;
    -webkit-appearance: none;
    border-radius: 0;
    cursor: pointer;
    font-weight: 300;
    border: 1px solid rgb(166, 166, 166);
    font-size: 13px;
    font-family: 'Montserrat';
    min-height: 230px;
    max-width: 100%;
}
iframe{
    width: 100%;
    display: block;
}
cite{
    display: inline-block;
    padding: 2px 8px;
    background: @color-grey;
    border-radius: 6px;
    font-style: normal;
    border: 1px solid @color-grey-dark;
    font-size: @font-size-small;
    pointer-events: none;
    i{
        pointer-events: none;
        margin-right: @spacing-xs;
    }
}

a{
    &.image-link{
        text-decoration: none;
        h3{
            color: #000;
            margin-top: @spacing-small;
            margin-bottom: 0;

        }
    }
}

.link{
    font-style: italic;
    font-size: @font-size-base;
    cursor: pointer;
    &:hover{
        text-decoration: none;
    }
    //modifier
    &--grey{
        color: @color-grey-darker;
    }
    &--underline{
        text-decoration: underline;
    }
}

.social{
    width: 95px;
    margin: 0 auto;
    .clearfix();
    a{
        float: left;
        text-decoration: none;
        color: @color-base;
        border: 1px solid @color-base;
        height: 40px;
        width: 40px;
        text-align: center;
        border-radius: 30px;
        margin-left: @spacing-base;
        &:first-child{
            margin-left: 0;
        }
        &:hover{
            background: @color-base;
            color: #fff;
        }
    }
    i{
        margin-top: 12px;
        display: block;
    }
}

.select{
    position: relative;
    &:after{
        content: '\f0d7';
        position: absolute;
        right: @spacing-base;
        top: 50%;
        transform: translateY(-50%);
        font-family: 'FontAwesome';
    }
    //modifiers
    &--small{
        select{
            padding: @spacing-small;
            font-weight: 300;
            padding-right: 25px;
        }
        &:after{
            right: @spacing-small;
        }
    }
}
b{
    font-weight: 500;
    i{
        margin-right: @spacing-small;
    }
}

img{
    width: 100%;
    display: block;
}
p{
    font-weight: 300;
    &.center{
        text-align: center;
    }
}
//default classes
.button-container{
    margin-top: @spacing-base;
}
.wrap{
    width: 95%;
    max-width: 980px;
    margin: 0 auto;
    .clearfix();
    &__row{
        margin-bottom: @spacing-xl;
        &:first-child{
            margin-top: @spacing-xl;
        }
        //modifiers
        &--small{
            margin-bottom: @spacing-base;
        }
    }
    &__header{
        text-align: center;
        padding: @spacing-base 10px @spacing-base @spacing-base;
        color: #fff;
        max-width: 200px;
        margin: 0 auto @spacing-base;
        font-size: @font-size-large;
        font-weight: 300;
        background: #000;
        letter-spacing: 5px;
    }
    //modifiers
    &--home{
        .wrap{
            &__row{
                &:first-child{
                    margin-top: 0;
                }
            }
        }
    }
    &--small{
        width: 50%;
    }
    //responsive
    @media screen and (max-width: @screen-size-medium){
        width: 95%;
        //blockifying
        .wrap{
            &__row{
                margin-top: @spacing-small;
            }
            &__header{
                text-align: center;
                min-width: 95%!important;
            }
        }
    }
}

.map{
    position: relative;
    &__overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        &--darker{
            background: rgba(0, 0, 0, 0.8);
        }
    }
    &__details{
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        color: #fff;
        font-size: 18px;
        width: 100%;
        i{
            font-size: 65px;
            margin-bottom: @spacing-base;
        }
    }
}

.text-center {
    text-align: center;
}