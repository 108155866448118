.show-more__buttons a {
  padding: 10px;
  display: block;
  width: 170px;
  text-align: center;
  margin: 15px auto 0;
  background: #000;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}
