.form h4 {
  margin-bottom: 10px;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}
.form .form__row {
  margin-top: 15px;
}
.form .form__row:after {
  content: '';
  display: block;
  clear: both;
}
.form .form__row:first-child {
  margin-top: 0;
}
.form .form__row b {
  margin-bottom: 5px;
  display: block;
}
.form .form__row input,
.form .form__row select {
  padding: 10px;
  font-weight: 300;
}
.form .form__row label {
  font-weight: 300;
}
.form .form__row[data-required] b:after {
  content: ' *';
  color: #e74c3c;
  margin-left: 2px;
}
.form .form__row--error input {
  border-color: #e74c3c;
}
.form .form__row--no-margin {
  margin-top: 0;
}
.form .form__link {
  float: right;
}
.form .form__message {
  background: #e74c3c;
  color: #fff;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #c0392b;
  font-size: 11px;
  float: left;
  position: relative;
}
.form .form__message:after,
.form .form__message:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.form .form__message:after {
  bottom: 100%;
  left: 15px;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #e74c3c;
}
.form .form__message:before {
  bottom: 100%;
  left: 15px;
  margin-left: -6px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid;
  border-bottom-color: #c0392b;
}
.form .form__message--full-width {
  float: none;
  width: 100%;
}
.form .form__message--full-width:after,
.form .form__message--full-width:before {
  content: '';
  height: 0;
  width: 0;
  position: initial;
  background: transparent;
  border: 0;
  margin: 0;
}
.form--card input.auto {
  width: auto;
}
.form [data-stripe='cvc'] {
  float: left;
}
.form [data-stripe='cvc'] + .form__message {
  clear: both;
}
.form .toggle--active + .form--billing {
  display: none;
}
