.tabbed:after {
  content: '';
  display: block;
  clear: both;
}
.tabbed__tabs--2 .tabbed__tab {
  width: 50%;
}
.tabbed__tabs:after {
  content: '';
  display: block;
  clear: both;
}
.tabbed__tab {
  width: 33.333%;
  float: left;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-bottom: 2px solid #eee;
}
.tabbed__tab i {
  margin-right: 15px;
}
.tabbed__tab--active {
  opacity: 1;
  border-bottom: 2px solid #000;
}
.tabbed__content {
  padding: 15px;
  display: none;
}
.tabbed__content:after {
  content: '';
  display: block;
  clear: both;
}
.tabbed__content p {
  line-height: 1.8;
  margin-top: 0;
  margin-bottom: 5px;
}
.tabbed__content--active {
  display: block;
}
.tabbed--vertical .tabbed__tabs {
  float: left;
  width: 30%;
  border-right: 1px solid #eee;
}
.tabbed--vertical .tabbed__contents {
  float: left;
  width: 70%;
  border-left: 1px solid #eee;
  margin-left: -1px;
}
.tabbed--vertical .tabbed__tab {
  width: 100%;
  border-bottom: 0;
  border-top: 1px solid #eee;
  text-align: left;
  padding: 30px;
}
.tabbed--vertical .tabbed__tab:hover {
  background: #f9f9f9;
}
.tabbed--vertical .tabbed__tab:first-child {
  border-top: 0;
}
.tabbed--vertical .tabbed__tab i {
  margin-right: 10px;
}
.tabbed--vertical .tabbed__tab--active {
  background: #000;
  color: #fff;
  border-color: #000;
  font-weight: 500;
}
.tabbed--vertical .tabbed__tab--active:hover {
  background: #000;
}
@media screen and (max-width: 720px) {
  .tabbed--vertical .tabbed__tabs {
    width: 100%;
    border-right: 0;
  }
  .tabbed--vertical .tabbed__contents {
    width: 100%;
    border-left: 0;
  }
  .tabbed--vertical .tabbed__tab {
    padding: 15px;
  }
}
