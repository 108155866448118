.colour-selector:after {
  content: '';
  display: block;
  clear: both;
}
.colour-selector input[type="color"] {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  line-height: 0;
  float: left;
  border: 0;
  font-size: 0;
}
.colour-selector__space {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #eee;
  float: left;
}
.colour-selector__colours {
  margin-bottom: 15px;
}
.colour-selector__colours:after {
  content: '';
  display: block;
  clear: both;
}
.colour-selector__colours__header {
  font-weight: 500;
  margin-bottom: 5px;
}
.colour-selector__new-colours,
.colour-selector__current-colours {
  float: left;
  margin-right: 5px;
}
.colour-selector__saved-colours {
  margin-right: 5px;
  float: left;
}
.colour-selector__colour {
  padding: 20px;
  float: left;
  border-radius: 30px;
  cursor: pointer;
  border: 3px solid #eee;
  margin-right: 5px;
  transform: scale(0.9);
  transition: all .1s ease;
}
.colour-selector__colour:hover {
  transform: scale(1);
}
.colour-selector__colour--black {
  background: #000;
}
.colour-selector__colour--black:hover {
  background: #333333;
}
.colour-selector__colour--white {
  background: #fff;
}
.colour-selector__colour--white:hover {
  background: #f5f5f5;
}
.colour-selector__colour--checked {
  position: relative;
  color: #eee;
  border-color: #eee;
  transform: scale(1);
}
.colour-selector__colour--checked:after {
  content: '\f00c';
  font-family: 'FontAwesome';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: #7a7a7a;
  border-radius: 30px;
  padding: 4px;
  font-size: 8px;
  background: #eee;
}
.colour-selector__colour--selected {
  color: #2980b9;
  border-color: #2980b9;
  transform: scale(1);
}
.colour-selector__colour--selected:after {
  background-color: #2980b9;
  color: #fff;
}
.colour-selector__colour--add {
  border: 2px dashed;
  color: #2980b9;
  position: relative;
}
.colour-selector__colour--add:after {
  content: '\f067';
  font-family: 'FontAwesome';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding: 0;
  color: #2980b9;
  background: transparent;
  font-size: 14px;
}
.colour-selector .tooltip {
  margin-left: 5px;
}
.colour-selector .tooltip:first-child {
  margin-left: 0;
}
.colour-selector .tooltip .colour-selector__colour {
  margin: 0;
}
.colour-selector .tooltip + .colour-selector__new-colours {
  padding-left: 5px;
}
.colour-selector .tooltip__content {
  bottom: 55px;
  text-align: center;
}
.colour-selector .tooltip__content input[type="text"] {
  background: transparent;
  color: #fff;
  padding: 0;
  font-weight: 300;
  font-size: 11px;
  border: 0;
  outline: none;
  text-align: center;
}
