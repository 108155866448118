@import '../_functions.less';
@import '../_variables.less';
.button{
    padding: @spacing-base;
    display: block;
    background: #000;
    float: left;
    text-align: center;
    color: #fff;
    font-size: @font-size-small;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    -webkit-appearance: none;
    border: 0;
    height: 44px;
    outline: none;
    line-height: normal;
    font-family: inherit;
    border-radius: 0;
    i{
        margin-right: @spacing-small;
    }
    &--large{
        min-width: 200px;
    }
    &--center{
        float: none;
        max-width: 200px;
        margin: 0 auto;
        font-weight: bold;
    }
    &--loader{
        position: relative;
        &:after{
            content: 'Adding...';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: inherit;
            font-size: 14px;
            line-height: 3.2;
            pointer-events: none;
            cursor: wait;
        }
    }
    &--warning{
        background: @color-danger;
    }
    &--success{
        background: @color-success;
        font-weight: 500;
    }
    &--white{
        background: #fff;
        color: #000;
    }
    &--outline{
        background: transparent;
        border: 1px solid @color-base;
        color: @color-base;
        &:hover{
            background: #000;
            color: #fff;
        }
    }
    &--50{
        width: 50%;
    }
    &&--right{
        float: right;
    }
    &--mini{
        font-size: 11px;
        float: right;
        padding: 3px 8px;
        cursor: pointer;
        background: @color-grey-dark;
        border-radius: 3px;
        height: auto;
        text-transform: none;
        color: inherit;
    }
    &--social-facebook{
        background: #3b5998;
        float: left;
        min-width: auto;
        padding: @spacing-base @spacing-large;
        font-size: 15px;
        font-family: 'FontAwesome';
        &:before{
            content: '\f09a';
        }
    }
    &--social-twitter{
        background: #4099ff;
        float: left;
        min-width: auto;
        padding: @spacing-base @spacing-large;
        font-size: 15px;
        font-family: 'FontAwesome';
        &:before{
            content: '\f099';
        }
    }
    &--center{
        float: none;
        width: 200px;
        margin: 0 auto;
    }
    &--absolute-right{
        position: absolute;
        right: 0;
    }
    &[disabled]{
        background: @color-grey;
        color: @color-grey-darker;
        cursor: not-allowed;
    }
}