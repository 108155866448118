@import '../_functions.less';
@import '../_variables.less';
/** _modal.less **/
.modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0) scale(0);
    background: #fff;
    width: 530px;
    max-width: 95%;
    z-index: 202;
    transition: transform .0s ease;
    max-height: 100vh;
    &--active{
        transform: translate3d(-50%,-50%,0) scale(1);
        transition: transform .25s ease;
    }
    &--large{
        width: 75%;
    }
    &__header{
        padding: @spacing-base;
        border-bottom: 1px solid @color-border;
        margin-bottom: 0;
    }
    &__row{
        margin-top: @spacing-base;
        b{
            margin-bottom: @spacing-small;
            display: block;
        }
    }
    &__sub-header{
        background: @color-grey-light;
        padding: @spacing-base;
        border-bottom: 1px solid @color-border;
        .clearfix();
        img{
            float: left;
            margin-left: @spacing-small;
            width: 30px;
            &:first-child{
                margin-left: 0;
            }
        }
        i{
            float: right;
            color: @color-success;
            font-size: @font-size-large;
        }
    }
    &__close{
        &--top{
            cursor: pointer;
            position: absolute;
            top: @spacing-base - 1;
            right: @spacing-base - 1;
            padding: @spacing-small;
            background: @color-danger;
            border: 1px solid @color-danger-dark;
            color: #fff;
            border-radius: 30px;
            text-align: center;
            font-size: 11px;
            line-height: 1;
            z-index: 1;
            i{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: 9px;
            }
        }
    }
    &__content{
        padding: @spacing-base;
    }
    &__buttons{
        .clearfix();
        a{
            float: right;
            &:first-child:not(.button--right){
                float: left;
            }
        }
        //modifier
        &--padding{
            padding: @spacing-base;
            border-top: 1px solid @color-border;
        }
    }
}

@media screen and (max-width: @screen-size-small){
    .modal{
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        & &__header{
            font-size: 13px;
        }
    }
}