@import '../_functions.less';
@import '../_variables.less';

.coming-soon{
	background: @color-grey-light;
	& &__container{
		width: 425px;
		max-width: 100%;
		margin: 0 auto;
		color: #fff;
		margin-top: @spacing-large;
		position: relative;
	}
	& &__header{
		padding: @spacing-large @spacing-large @spacing-base;
		margin-bottom: 0;
		text-align: center;
		font-weight: 300;
		font-size: @font-size-large;
		@media screen and (max-width: @screen-size-small){
			padding: @spacing-base @spacing-base 0;
		}
	}
	& &__content{
		padding: @spacing-base;
	}
	& &__icon{
		font-size: 90px;
		text-align: center;
		margin-bottom: @spacing-base;
		color: #000;
	}
	body{
		background: inherit;
	}
	//child blocks
	.wrap{
		&__header{
			width: 700px;
			max-width: 90%;
			text-transform: uppercase;
		}
	}
	.form{
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.5);
		b{
			margin-bottom: 0px;
			background: #fff;
			color: #444;
			padding: @spacing-xs @spacing-base;
			&:after{
				font-weight: 500;
				font-size: 20px;
			}
		}
		input{
			padding: @spacing-base;
			border: 0;
			outline: none;
		}
	}
}