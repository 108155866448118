@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.side-cart {
  position: fixed;
  width: 375px;
  max-width: 90%;
  top: 0;
  background: #fff;
  height: 100%;
  z-index: 102;
  right: 0;
  transform: translate3d(100%, 0, 0);
  transition: transform .25s ease;
  z-index: 201;
}
.side-cart__header {
  margin: 0;
  background: #000;
  color: #fff;
  font-weight: 300;
  padding: 15px;
}
.side-cart__content {
  padding: 15px;
  position: relative;
}
.side-cart__content:after {
  content: '';
  display: block;
  clear: both;
}
.side-cart__close {
  float: right;
  cursor: pointer;
}
.side-cart__open {
  position: relative;
}
.side-cart__quantity {
  position: absolute;
  font-size: 7px;
  padding: 2px 4.5px;
  background: #e74c3c;
  border: 1px solid #c0392b;
  color: #fff;
  border-radius: 30px;
  bottom: 7px;
  left: 22px;
}
.side-cart__items {
  overflow: auto;
  border: 0;
  margin-top: 15px;
  position: fixed;
  bottom: 107px;
  background-color: #fff;
  top: 36px;
  z-index: 1;
  left: 0;
  width: 100%;
}
.side-cart .side-cart__item {
  padding: 15px;
  border-top: 1px solid #eee;
  position: relative;
  width: 100%;
  float: left;
}
.side-cart .side-cart__item:after {
  content: '';
  display: block;
  clear: both;
}
.side-cart .side-cart__item:first-child {
  border-top: 0;
}
.side-cart .side-cart__item__image {
  float: left;
  width: 30%;
  padding-right: 3%;
  position: relative;
}
.side-cart .side-cart__item__remove {
  background: #e74c3c;
  position: absolute;
  top: 0px;
  left: 0;
  color: #fff;
  line-height: 0;
  padding: 5px 5px;
  border-radius: 0px;
  font-size: 11px;
  cursor: pointer;
}
.side-cart .side-cart__item__content {
  width: 70%;
  float: left;
}
.side-cart .side-cart__item__name {
  float: 70%;
  float: left;
}
.side-cart .side-cart__item__price {
  float: right;
  width: 30%;
  text-align: right;
}
.side-cart .side-cart__item__size {
  clear: both;
  margin-top: 10px;
}
.side-cart .side-cart__item__quantity {
  float: left;
  line-height: 1.5;
  margin-top: 10px;
}
.side-cart .side-cart__item__quantity:after {
  content: '';
  display: block;
  clear: both;
}
.side-cart .side-cart__item__decrement,
.side-cart .side-cart__item__increment {
  float: left;
  color: #2980b9;
  border: 1px solid #2980b9;
  padding: 4px 7px;
  font-size: 8px;
  border-radius: 18px;
  cursor: pointer;
}
.side-cart .side-cart__item__decrement:hover,
.side-cart .side-cart__item__increment:hover {
  background: #e1f0fa;
}
.side-cart .side-cart__item__decrement[data-disabled="true"] {
  border-color: #e0e0e0;
  color: #e0e0e0;
  pointer-events: none;
  cursor: not-allowed;
}
.side-cart .side-cart__item__qty {
  float: left;
  padding: 0 10px;
}
.side-cart__loader-container--active:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;
}
.side-cart__loader-container--active .side-cart__loader {
  margin: 15px auto;
  font-size: 10px;
  position: absolute;
  text-indent: -9999em;
  border-top: 0.3em solid #e1f0fa;
  border-right: 0.3em solid #e1f0fa;
  border-bottom: 0.3em solid #e1f0fa;
  border-left: 0.3em solid #2980b9;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  -webkit-animation: loader 1.1s infinite linear;
  animation: loader 1.1s infinite linear;
  z-index: 2;
}
.side-cart__loader-container--active .side-cart__loader:after,
.side-cart__loader-container--active .side-cart__loader {
  border-radius: 50%;
  width: 4em;
  height: 4em;
}
.side-cart__loader-container--active .side-cart__loader.tiny:after,
.side-cart__loader-container--active .side-cart__loader.tiny {
  border-radius: 50%;
  width: 4em;
  height: 4em;
}
.side-cart__totals {
  position: absolute;
  bottom: 0;
  padding: 15px;
  width: 100%;
  border-top: 1px solid #eee;
  background: #f9f9f9;
  z-index: 1;
}
.side-cart__total-row {
  margin-bottom: 15px;
}
.side-cart__total-row:after {
  content: '';
  display: block;
  clear: both;
}
.side-cart__total-row b {
  float: right;
}
.side-cart__buttons:after {
  content: '';
  display: block;
  clear: both;
}
.side-cart__buttons a {
  float: left;
  width: 100%;
  min-width: initial;
}
.side-cart--active.side-cart {
  transform: translate3d(0, 0, 0);
}
.side-cart--hidden .side-cart__totals {
  display: none;
}
