@import '../_functions.less';
@import '../_variables.less';
.show-more{
	&__buttons{
		a{
			padding: @spacing-small;
			display: block;
			width: 170px;
			text-align: center;
			margin: @spacing-base auto 0;
			background: #000;
			border-radius: 30px;
			color: #fff;
			text-decoration: none;
			font-size: 12px;
		}
	}
}