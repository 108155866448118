.newsletter-signup__form__tagline {
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  letter-spacing: 3px;
}
.newsletter-signup__form .grid {
  margin: 0;
}
.newsletter-signup__form .grid__item {
  position: relative;
  padding: 0;
}
.newsletter-signup__form .form {
  padding: 30px;
}
