.video {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.video:not(i) {
  margin: 85px auto 0;
}
.video video {
  display: block;
  width: 100%;
}
.video__header {
  position: absolute;
  top: 0;
  left: -1px;
  margin: 0;
  padding: 15px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  font-weight: 300;
  font-size: 18px;
  z-index: 2;
}
.video i {
  position: initial;
}
.video i:before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 4;
  transform: translateX(-50%) translateY(-50%);
  color: #fff;
  font-size: 6rem;
  text-shadow: 0px 2px 10px rgba(34, 36, 38, 0.2);
  z-index: 1;
  cursor: pointer;
}
.video img {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 0;
}
