@import '../_functions.less';
@import '../_variables.less';
@import '../_animations.less';
/** checkout-cart.less **/
.checkout-cart{
	background: #fff;
	border: 1px solid @color-border;
	&__header{
		padding: @spacing-base;
		margin-bottom: 0;
		border-bottom: 1px solid @color-border;
	}
	&__sub-header{
		background: @color-grey-light;
		padding: @spacing-base;
		border-bottom: 1px solid @color-border;
		.clearfix();
		img{
			float: left;
			margin-left: @spacing-small;
			width: 30px;
			&:first-child{
				margin-left: 0;
			}
		}
		i{
			float: right;
			color: @color-success;
			font-size: @font-size-large;
		}
	}
	&__content{
		padding: @spacing-base;
        position: relative;
		.clearfix();
	}
	&__button{
		//modifiers
		&--top{
			padding: @spacing-base;
			margin: -@spacing-base -@spacing-base @spacing-base;
			border-bottom: 1px solid @color-border;
			font-weight: 500;
			a{
				color: inherit;
				text-decoration: none;
			}
			i{
				margin-right: @spacing-xs;
			}
		}
	}
    &__quantity{
        float: right;
        font-size: @font-size-small;
        font-weight: 300;
        background: @color-danger;
        color: #fff;
        padding: @spacing-xs @spacing-small - 1;
        border-radius: 30px;
        margin-top: -3px;
        border: 1px solid darken(@color-danger, 20%)
    }
    & &__item{
        padding-top: @spacing-base;
        margin-top: @spacing-base;
        border-top: 1px solid @color-border;
        position: relative;
        .clearfix();
        &:first-child{
            border-top: 0;
            padding-top: 0;
            margin-top: 0;
        }
        &__image{
            float: left;
            width: 30%;
            padding-right: 3%;
            position: relative;
        }
        &__remove{
            background: @color-danger;
            position: absolute;
            top: 0px;
            left: 0;
            color: #fff;
            line-height: 0;
            padding: 5px 5px;
            border-radius: 0px;
            font-size: 11px;
            cursor: pointer;
        }
        &__content{
            width: 70%;
            float: left;
        }
        &__name{
            float: 70%;
            float: left;
        }
        &__price{
            float: right;
            width: 30%;
            text-align: right;
        }
        &__size{
            clear: both;
            margin-top: @spacing-small;
        }
        &__quantity{
            float: left;
            line-height: 1.5;
            margin-top: @spacing-small;
            .clearfix();
        }
        &__decrement,
        &__increment{
            float: left;
            color: @color-info-dark;
            border: 1px solid @color-info-dark;
            padding: 6px 7px;
            font-size: 8px;
            border-radius: 18px;
            cursor: pointer;
            line-height: 1;
            &:hover{
                background: @color-info-light;
            }
        }
        &__decrement[data-disabled="true"]{
            border-color: @color-grey-dark;
            color: @color-grey-dark;
            pointer-events: none;
            cursor: not-allowed;
        }
        &__qty{
            float: left;
            padding: 0 @spacing-small;
        }
    }
    &__loader-container{
        //modifiers
        &--active{
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: rgba(255, 255, 255, 0.8);
                z-index: 2;
            }
            .checkout-cart{
                &__loader{
                    .loader(@color-info, @color-info-light);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -4em;
                    margin-left: -4em;
                    z-index: 2;
                }
            }
        }
        &__loader{
        }
    }
	//modifiers
	&--info{
		text-align: center;
	}
}

// Respsonsive
@media screen and (max-width: @screen-size-large){
}