@import '../_functions.less';
@import '../_variables.less';
.table{
	& &__row{
		.clearfix();
		//modifiers
		&--header{
			font-weight: 500;
		}
	}
	& &__cell{
		float: left;
		padding: @spacing-small;
		text-align: center;
		b{
			margin: 0;
		}
		//modifiers
		&--empty{
			opacity: 0;
		}
	}
	&__header{
		background: @color-grey-light;
		padding: @spacing-base;
		text-transform: uppercase;
		i{
			margin-right: @spacing-small;
		}
	}
	&__footer{
		background: @color-grey-light;
		padding: @spacing-base;
		border-top: 1px solid @color-border;
		float: left;
		width: 100%;
		.clearfix();
		b{
			margin-right: @spacing-small;
		}
		i{
			margin-right: @spacing-xs;
		}
	}
	//modifiers
	&--column{
		.table{
			&__cell{
				width: 25%;
			}
		}
	}
	&--border{
		border: 1px solid @color-border;
		width: 100%;
		margin-top: @spacing-base;
		margin-bottom: @spacing-large;
		.clearfix();
		.table{
			&__cell{
				padding: @spacing-base;
				&--img{
					width: 1%;
					img{
						width: 30px;
					}
				}
			}
			&__row{
				float: left;
				width: 100%;
				background: #fff;
				border-top: 1px solid @color-border;
				.clearfix();
				&:first-child{
					border-top: 0;
				}
			}
		}
	}
	&--margin{
		margin-top: @spacing-base;
		&:first-child{
			margin-top: 0;
		}
	}
 	&--4-column{
 		//blockifying
		.table{
			&__cell{
				width: 25%;
			}
		}
	}
	&__tag{
		padding: @spacing-base;
		max-width: 65px;
		border-radius: 3px;
	}
}

.table{
	&__actions{
		text-align: right;
	}
}

table{
	border: 1px solid @color-border;
	border-collapse: collapse;
	width: 100%;
	th{
		padding: @spacing-base;
		background: @color-grey;
		font-weight: 500;
		text-align: left;
		&.table__actions{
			text-align: right;
		}
		&.table__centered{
			text-align: center;
		}
	}
	td{
		padding: @spacing-base;
		&.table__centered{
			text-align: center;
		}
	}
	tr{
		border-top: 1px solid @color-border;
	}
	.table__success{
		color: @color-success;
	}
	.table__error{
		color: @color-danger;
	}
	.tooltip{
		float: none;
	}
}
//new table
.table-new{
	border: 1px solid @color-border;
	& &__headers{
		background: @color-grey;
		border-bottom: 1px solid @color-border;
		font-weight: 500;
		.clearfix();
	}
	& &__header,
	& &__cell{
		padding: @spacing-base;
		float: left;
		&--no-results{
			text-align: center;
		}
		&--12{
			width: 100%;
		}
		&--6{
			width: 50%;
		}
		&--3{
			width: 25%;
		}
		&--4{
			width: 33.33%;
		}
	}
	& &__row{
		border-top: 1px solid @color-border;
		.clearfix();
		&:first-child{
			border-top: 0;
		}
	}
}